<div class="content">
    <div class="container-fluid">
        <div class="page-title-box">
            <div class="page-title-right" *menuButton="'masters_add_package_module_back'">
                <button type="button" class="btn btn-outline-secondary btn-sm" (click)="onBack()">
                    <i class="fe-arrow-left mr-1"></i> Back
                </button>
            </div>
            <h4 class="page-title" [textContent]="packageMouduleId ? 'Modify Package' : 'Add Package'"></h4>
        </div>
        <div class="d-flex align-items-center" *ngIf="loadingPackageInfo && packageMouduleId">
            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
            <span class="ml-2">Please wait while loading Package Details ...</span>
        </div>
        <div class="mh-300 position-relative" *ngIf="!loadingPackageInfo && !packageInfo && packageMouduleId">
            <no-data [title]="'Package Details'"></no-data>
        </div>
        <ng-container *ngIf="!loadingPackageInfo && ((!packageInfo && !packageMouduleId) || (packageInfo && packageMouduleId))">
            <div class="row">
                <div class="col-6">
                    <div class="card mb-0">
                        <div class="card-body font-13 p-2">
                            <form [formGroup]="packageForm" (ngSubmit)="onSubmit()">
                                <div class="row row-sm">
                                    <div class="col-6 col">
                                        <div class="form-group">
                                            <label class="mb-0 font-weight-bold font-13">Package Name <code>*</code></label>
                                            <input type="text" class="form-control font-13" maxlength="100" block formControlName="packageName" placeholder="Enter package name" autocomplete="nope" [ngClass]="{ 'is-invalid': isSubmitted && form.packageName?.errors }" />
                                            <span *ngIf="isSubmitted && form.packageName?.errors" class="invalid-feedback">- is required</span>
                                        </div> 
                                    </div>
                                    <div class="col-3 col"> 
                                        <div class="form-group">
                                            <label class="mb-0 font-weight-bold font-13">Package Type <code>*</code></label>
                                            <ng-select class="font-13" placeholder="Select type" (change)="onChangePackageType($event?.value)" [clearable]="false" formControlName="packageType" [items]="packageTypes" bindLabel="name" bindValue="value" [ngClass]="{ 'is-invalid': isSubmitted && form.packageType?.errors }">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <span class="font-13" [textContent]="item.name"></span>
                                                </ng-template>
                                                <ng-template ng-label-tmp let-item="item">
                                                    <span class="font-13" [textContent]="item.name"></span>
                                                </ng-template>
                                            </ng-select>
                                            <span *ngIf="isSubmitted && form.packageType?.errors" class="invalid-feedback">- is required</span>
                                        </div>
                                    </div>
                                    <div class="col-3 col">
                                        <div class="form-group">
                                            <label class="mb-0 font-weight-bold font-13">Pay Type <code>*</code></label>
                                            <ng-select class="font-13" placeholder="Select type" (change)="onChangePayType($event?.value)" [clearable]="false" formControlName="payType" [items]="payTypes" bindLabel="name" bindValue="value" [ngClass]="{ 'is-invalid': isSubmitted && form.payType?.errors }">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <span class="font-13" [textContent]="item.name"></span>
                                                </ng-template>
                                                <ng-template ng-label-tmp let-item="item">
                                                    <span class="font-13" [textContent]="item.name"></span>
                                                </ng-template>
                                            </ng-select>
                                            <span *ngIf="isSubmitted && form.payType?.errors" class="invalid-feedback">- is required</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row row-sm">
                                    <div class="col-6 col">
                                        <div class="form-group">
                                            <label class="mb-0 font-weight-bold">Location <code>*</code></label>
                                            <ng-select [items]="locations" [loading]="loadingLocations" (change)="onChangeLocation($event)" [clearable]="false" bindLabel="name" placeholder="Select location" formControlName="location" [ngClass]="{ 'is-invalid': isSubmitted && form.location?.errors }">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <span class="font-13" [textContent]="item.name"></span>
                                                </ng-template>
                                                <ng-template ng-label-tmp let-item="item">
                                                    <span class="font-13" [textContent]="item.name"></span>
                                                </ng-template>
                                            </ng-select>
                                            <span *ngIf="isSubmitted && form.location?.errors" class="invalid-feedback">- is required</span>
                                        </div>
                                    </div>
                                    <div class="col-6 col">
                                        <div class="form-group font-13">
                                            <label class="mb-0 font-weight-bold">Charge Template <code>*</code></label>
                                            <ng-select (change)="onChangeChargeTemplate($event)" [items]="chargeTemplates" [ngClass]="{ 'is-invalid': isSubmitted && form.chargeTemplate?.errors }" [loading]="loadingChargeTemplates" [clearable]="false" bindLabel="name" [notFoundText]="'No Charge Template found'" placeholder="Select charge template" formControlName="chargeTemplate">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <span class="font-weight-normal font-13" [textContent]="item.name"></span>
                                                </ng-template>
                                                <ng-template ng-label-tmp let-item="item">
                                                    <span class="font-weight-normal font-13" [textContent]="item.name"></span>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row row-sm">
                                    <div class="col-6 col">
                                        <div class="form-group">
                                            <label class="mb-0 font-weight-bold">Doctor</label>
                                            <ng-select [items]="providers" [loading]="loadingProviders" bindLabel="value" placeholder="Select doctor" formControlName="provider" [notFoundText]="'No doctots found'" [ngClass]="{ 'is-invalid': isSubmitted && form.provider?.errors }">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <span class="font-weight-semibold text-capitalize font-13" [textContent]="item.value"></span>
                                                    <small class="text-mute text-capitalize ml-1 font-11" [textContent]="'(' + item.optionalText + ')'"></small>
                                                </ng-template>
                                                <ng-template ng-label-tmp let-item="item">
                                                    <span class="font-weight-semibold text-capitalize font-13" [textContent]="item.value"></span>
                                                    <small class="text-mute text-capitalize ml-1 font-11" [textContent]="'(' + item.optionalText + ')'"></small>
                                                </ng-template>
                                            </ng-select>
                                            <span *ngIf="isSubmitted && form.provider?.errors" class="invalid-feedback">- is required</span>
                                        </div>
                                    </div>
                                    <div class="col-6 col">
                                        <div class="form-group">
                                            <label class="mb-0 font-weight-bold font-13">Expiry Days <small>(When the Package Is Activated)</small></label>
                                            <input type="number" min="1" max="500" step="1" class="form-control font-13" formControlName="expiresIn" placeholder="##" [ngClass]="{ 'is-invalid': isSubmitted && form.expiresIn?.errors }" />
                                            <span *ngIf="isSubmitted && form.expiresIn?.errors" class="invalid-feedback">- is invalid</span>
                                        </div>
                                    </div>
                                    <div class="col-12 col">
                                        <div class="form-group">
                                            <label class="mb-0 font-weight-bold font-13">Modules <code>*</code></label>
                                            <ng-select [items]="modules" [loading]="loadingModules" [clearable]="true" [hideSelected]="true" [multiple]="true" placeholder="Select module name" bindLabel="moduleName" (add)="onAddModule($event)" (remove)="onRemoveModule($event.value)" formControlName="modules" [ngClass]="{ 'is-invalid': isSubmitted && form.modules?.errors }">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <div class="media p-0 font-13">
                                                        <div class="avatar-xs mr-1">
                                                            <span class="avatar-title rounded-circle font-11 font-weight-bold bg-soft-warning text-primary">
                                                                <i [class]="item.moduleIcon || 'fe-airplay'"></i>
                                                            </span>
                                                        </div>
                                                        <div class="media-body overflow-hidden">
                                                            <span class="font-weight-semibold" [textContent]="item.moduleName"></span>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                            <span *ngIf="isSubmitted && form.modules?.errors" class="invalid-feedback">- is required</span>
                                        </div>
                                    </div>
                                    <div class="col-12 col mb-3" *ngIf="form.modules.value?.length">
                                        <label class="mb-1 font-weight-bold font-13">Charge Details <code>*</code></label>
                                        <div class="package-details-section with-border" *ngFor="let cm of form.modules.value; trackBy: trackBy.module;">
                                            <div class="col-12 col with-border" *ngIf="cm.isChargeCategoryApplicable && (cm.packageType === packageType.SingleSelect || cm.packageType === packageType.MultiSelect)">
                                                <div class="mb-3 package-module-title">
                                                    <div class="media p-0 font-13">
                                                        <div class="avatar-xs mr-1">
                                                            <span class="avatar-title rounded-circle font-11 bg-soft-warning text-primary">
                                                                <i [class]="cm.moduleIcon || 'fe-airplay'"></i>
                                                            </span>
                                                        </div>
                                                        <div class="media-body overflow-hidden" [textContent]="cm.moduleName"></div>
                                                    </div>
                                                    <a class="cursor-pointer font-12" *ngIf="cm.isChargeCategoryApplicable" href="javascript:;" (click)="onOpenModal(templateModifyCharges, cm)">
                                                        <i class="fe-plus"></i> Add
                                                    </a>
                                                </div>
                                                <ng-container *ngIf="cm.isChargeCategoryApplicable && (cm.packageType === packageType.SingleSelect || cm.packageType === packageType.MultiSelect)">
                                                    <table class="table mt-2 px-1 table-centered table-light-border table-sm mb-0" *ngIf="filterModuleCharges(cm.modulesMasterId)?.length">
                                                        <tbody>
                                                            <tr *ngFor="let item of filterModuleCharges(cm.modulesMasterId); trackBy: trackBy.moduleCharge; let i = index">
                                                                <td style="width: 30px" [textContent]="item.quantity"></td>
                                                                <td [textContent]="item.chargeName || '-'"></td>
                                                                <td style="width: 90px" *ngIf="item.repeatTypeName">
                                                                    <small [textContent]="item.repeatTypeName" class="text-muted"></small>
                                                                </td>
                                                                <td style="width: 135px">
                                                                    <small *ngIf="item.isFree" class="text-muted">Free</small>
                                                                </td>
                                                                <td style="width: 50px" class="text-right">
                                                                    <a class="text-danger cursor-pointer" (click)="onRemoveCharge(cm.modulesMasterId, i)"><i class="fe-x"></i></a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </ng-container>
                                            </div>
                                            <ng-container *ngIf="filterModuleCharges(cm.modulesMasterId).length>0">
                                                <div class="row row-sm">
                                                    <div *ngIf="cm.isChargeCategoryApplicable && (cm.packageType === packageType.SingleSelect || cm.packageType === packageType.MultiSelect)">
                                                        <div class="form-group d-flex align-items-center">
                                                            <input type="checkbox" class="form-control form-control-sm mb-0 ml-2 pack-size" [(ngModel)]="cm.isAllInclude" (ngModelChange)="onChangePackageLImitValue(cm)" [ngModelOptions]="{standalone: true}" autocomplete="nope" />
                                                            <label class="mb-0 mr-2">All Include</label>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="!cm.isAllInclude && cm.isChargeCategoryApplicable && (cm.packageType === packageType.SingleSelect || cm.packageType === packageType.MultiSelect)">
                                                        <div class="form-group d-flex align-items-center">
                                                            <label class="ml-1 mb-0 mr-2">Included Limit</label>
                                                            <input type="number" class="form-control form-control-sm" [(ngModel)]="cm.includedLimit" (ngModelChange)="onChangePackageLImitValue(cm)" [ngModelOptions]="{standalone: true}" style="width: 100px" min="0" step="1" block placeholder="###" autocomplete="nope" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <div class="package-module-title" *ngIf="!cm.isChargeCategoryApplicable && (cm.packageType === packageType.Limit || cm.packageType === packageType.Quantity)">
                                                <div class="media p-0 font-13">
                                                    <div class="avatar-xs mr-1">
                                                        <span class="avatar-title rounded-circle font-11 bg-soft-warning text-primary">
                                                            <i [class]="cm.moduleIcon || 'fe-airplay'"></i>
                                                        </span>
                                                    </div>
                                                    <div class="media-body overflow-hidden" [textContent]="cm.moduleName"></div>
                                                </div>
                                            </div>                     
                                            <div class="form-group mb-1 d-flex align-items-center" *ngIf="!cm.isChargeCategoryApplicable && cm.packageType === packageType.Limit">
                                                <label class="mb-0 ml-1 mr-2 font-weight-semibold font-13">
                                                    <span>Maximum Amount</span><small class="text-muted ml-1">(to be used)</small>
                                                </label>
                                                <input type="number" class="form-control font-13 mr-1" [(ngModel)]="cm.modelValue" [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeModelValue(cm)" style="width: 200px" min="0" step="1" block placeholder="INR ####.##" autocomplete="nope" />
                                            </div>
                                            <div class="form-group mb-1 d-flex align-items-center" *ngIf="!cm.isChargeCategoryApplicable && cm.packageType === packageType.Quantity">
                                                <label class="mb-0 ml-1 mr-2 font-weight-semibold font-13">
                                                    <span>No.of {{cm.moduleName}}</span><small class="text-muted ml-1">(to be used)</small>
                                                </label>
                                                <input type="number" class="form-control font-13 mr-1" [(ngModel)]="cm.modelValue" [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeModelValue(cm)" style="width: 200px" min="0" step="1" block placeholder="###" autocomplete="nope" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col">
                                        <div class="form-group">
                                            <label class="mb-0 font-weight-bold font-13">Exclusions</label>
                                            <textarea type="text" class="form-control font-13" style="height: 60px" rows="3" cols="25" maxlength="1000" block formControlName="exclusions" placeholder="Enter exclusions" autocomplete="nope"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-6 col">
                                        <div class="form-group">
                                            <label class="mb-0 font-weight-bold font-13">Package Notes</label>
                                            <textarea type="text" class="form-control font-13" style="height: 60px" rows="3" cols="25" maxlength="500" block formControlName="notes" placeholder="Enter notes" autocomplete="nope"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-6 col">
                                        <div class="form-group mb-0">
                                            <label class="font-weight-bold mb-0">Discount Type</label>
                                            <div class="row row-sm">
                                                <div class="col" [class.col-6]="form.discountType?.value" [class.col-12]="!form.discountType?.value">
                                                    <div class="form-group mb-0">
                                                        <ng-select formControlName="discountType" (change)="onChangeDiscountType($event)" [clearable]="true" placeholder="Select discount type" class="font-13">
                                                            <ng-option value="A">Amount</ng-option>
                                                            <ng-option value="P">Percentage</ng-option>
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="col-6 col" *ngIf="form.discountType?.value">
                                                    <div class="form-group mb-0" *ngIf="form.discountType.value === 'P'">
                                                        <div class="input-group mb-0" [ngClass]="{ 'is-invalid': isSubmitted && form.discountPercentage?.errors }">
                                                            <input class="form-control font-13" type="number" min="1" max="100" step="1" block formControlName="discountPercentage" placeholder="###.##">
                                                            <div class="input-group-text input-group-append">%</div>
                                                        </div>
                                                        <div *ngIf="isSubmitted && form.discountPercentage?.errors" class="invalid-feedback">
                                                            <span *ngIf="form.discountPercentage?.errors?.required">- is required</span>
                                                            <span *ngIf="!form.discountPercentage?.errors?.required">- is invalid</span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group mb-0" *ngIf="form.discountType.value === 'A'">
                                                        <div class="input-group mb-0" [ngClass]="{ 'is-invalid': isSubmitted && form.discountAmount?.errors }">
                                                            <div class="input-group-text input-group-prepend">₹</div>
                                                            <input class="form-control font-13" type="number" min="1" step="1" block formControlName="discountAmount" placeholder="###.##">
                                                        </div>
                                                        <div *ngIf="isSubmitted && form.discountAmount?.errors" class="invalid-feedback">
                                                            <span *ngIf="form.discountAmount?.errors?.required">- is required</span>
                                                            <span *ngIf="!form.discountAmount?.errors?.required">- is invalid</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col">
                                        <div class="form-group mb-0">
                                            <label class="mb-0 font-weight-bold font-13">Comments</label>
                                            <textarea type="text" class="form-control font-13" style="height: 60px" rows="3" cols="25" block formControlName="comments" placeholder="Enter comments" autocomplete="nope"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-3" />
                                <div class="justify-content-end d-flex">
                                    <button type="button" class="btn btn-sm btn-outline-secondary ml-1" (click)="onBack()">Cancel</button>
                                    <button type="submit" [disabled]="isSubmitting" tabindex="0" class="btn btn-primary btn-sm ml-1">
                                        <span *ngIf="isSubmitting">
                                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                            Please wait..
                                        </span>
                                        <span *ngIf="!isSubmitting">Submit</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card mb-0 h-100">
                        <h5 class="card-header pl-2 pr-2 bg-light heading2">
                            <div class="font-16">
                                <span *ngIf="!form.packageName?.value" [textContent]="'Package Preview'"></span>
                                <ng-container *ngIf="form.packageName?.value">
                                    <span class="text-primary" [textContent]="form.packageName.value"></span>
                                    <ng-container *ngIf="form.payType?.value && form.packageType?.value">
                                        <span class="ml-1 font-13" [textContent]="'(' + (form.payType.value | payType) + ' / '"></span>
                                        <span class="font-13" [textContent]="(form.packageType.value | packageType) + ')'"></span>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <ng-container *ngIf="form.packageName?.value">
                                <div class="font-13 mt-1">
                                    <span *ngIf="form.location?.value" [textContent]="form.location.value.name"></span>
                                    <small *ngIf="form.chargeTemplate?.value?.name" class="text-mute ml-1" [textContent]="'(' + form.chargeTemplate.value.name + ')'"></small>
                                </div>
                                <div class="d-flex align-items-center font-weight-normal font-13 mt-2 mb-1" *ngIf="form.provider?.value">
                                    <span class="text-dark" style="width: 70px; display: inline-block;">Doctor</span>
                                    <div>
                                        <span class="text-capitalize" [textContent]="form.provider.value.value"></span>
                                        <small class="text-capitalize ml-1" [textContent]="'(' + form.provider.value.optionalText + ')'"></small>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center text-mute font-weight-normal font-13" *ngIf="form.expiresIn?.value">
                                    <span class="text-dark" style="width: 70px; display: inline-block;">Expires in</span>
                                    <div>
                                        <span [textContent]="form.expiresIn.value"></span>
                                        <span class="ml-1" [textContent]="'days'"></span>
                                    </div>
                                </div>
                            </ng-container>
                        </h5>
                        <div class="card-body p-2">
                            <div class="no-package-container" *ngIf="!charges?.length">
                                <img src="/assets/images/package-preview.png" alt="Package Preview" />
                                <p class="mt-2 mb-0 text-mute">To preview the package, please select the package information on the left.</p>
                            </div>
                            <ng-container *ngIf="charges?.length">
                                <label class="mb-1 text-primary w-100 font-13 font-weight-bold">Package Details</label>
                                <div class="package-details-container">
                                    <div class="package-details-section" *ngFor="let charge of charges;">
                                        <div class="package-module-title" [textContent]="charge.module.moduleName"></div>
                                        <table class="table table-centered table-light-border mb-0">
                                            <tbody>
                                                <tr *ngFor="let item of charge.moduleCharges;">
                                                    <td style="width: 30px" [textContent]="item.quantity"></td>
                                                    <td *ngIf="charge.module.isChargeCategoryApplicable" [textContent]="item.chargeName || '-'"></td>
                                                    <td *ngIf="!charge.module.isChargeCategoryApplicable && charge.module.packageType === packageType.Limit" [textContent]="charge.module.moduleName + ' Amount'"></td>
                                                    <td *ngIf="!charge.module.isChargeCategoryApplicable && charge.module.packageType === packageType.Quantity" [textContent]="'No.of ' + charge.module.moduleName"></td>
                                                    <td style="width: 90px" *ngIf="item.repeatTypeName && charge.module.isChargeCategoryApplicable" [textContent]="item.repeatTypeName">
                                                        <small [textContent]="item.repeatTypeName" class="text-muted"></small>
                                                    </td>
                                                    <td class="text-right" style="width: 135px">
                                                        <span *ngIf="!item.isFree && item.amount" [textContent]="item.amount | currency :'₹ ':'symbol':'1.2-2'"></span>
                                                        <small *ngIf="item.isFree">Free <span class="font-10 text-muted" [textContent]="'(' + ((item.amount || 0) | currency :'₹ ':'symbol':'1.2-2') + ')'"></span></small>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <table class="table mt-2 mb-0 table-borderless font-13">
                                        <tbody>
                                            <tr class="h-32">
                                                <td style="width: 135px" class="pl-0 font-weight-bold">Exclusions</td>
                                                <td>
                                                    <p class="mb-0 pre-line" *ngIf="form.exclusions?.value" [textContent]="form.exclusions.value"></p>
                                                    <span *ngIf="!form.exclusions?.value" class="text-muted">Not recorded.</span>
                                                </td>
                                            </tr>
                                            <tr class="h-32">
                                                <td class="pl-0 font-weight-bold pt-0">Package Notes</td>
                                                <td class="pt-0">
                                                    <p class="mb-0 pre-line" *ngIf="form.notes?.value" [textContent]="form.notes.value"></p>
                                                    <span *ngIf="!form.notes?.value" class="text-muted">Not recorded.</span>
                                                </td>
                                            </tr>
                                            <tr class="h-32">
                                                <td class="pl-0 font-weight-bold pt-0">Comments</td>
                                                <td class="pt-0">
                                                    <p class="mb-0 pre-line" *ngIf="form.comments?.value" [textContent]="form.comments.value"></p>
                                                    <span *ngIf="!form.comments?.value" class="text-muted">Not recorded.</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="table table-centered package-amounts">
                                        <tbody>
                                            <tr>
                                                <td>Total Quantity</td>
                                                <td [textContent]="form.quantity?.value || 0"></td>
                                            </tr>
                                            <tr class="text-success">
                                                <td>Discount</td>
                                                <td [textContent]="(form.discountType?.value === 'P' && form.discountPercentage?.value) ? form.discountPercentage.value + '%' : ((form.discountAmount?.value || 0) | currency :'₹ ':'symbol':'1.2-2')"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<ng-template #templateModifyCharges>
    <div class="modal-header d-block">
        <div class="d-flex align-items-center justify-content-between">
            <h4 class="modal-title">
                <i [class]="selectedModule.moduleIcon"></i>
                <span class="ml-1" [textContent]="selectedModule.moduleName"></span>
            </h4>
            <div class="d-flex">
                <div class="input-group mb-0" *ngIf="moduleCharges?.length || filters.applied" style="width: 250px; margin-right: 0.25rem;">
                    <input class="form-control" type="text" autofocus autocomplete="nope" [(ngModel)]="filters.options.chargeName" (keyup.enter)="onFilterCharges()" placeholder="Search charge name" />
                    <div class="input-group-text input-group-append cursor-pointer text-danger" *ngIf="filters.applied" (click)="onResetFilterCharges()"><i class="mdi mdi-filter-variant-remove"></i></div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
            </div>
        </div>
    </div>
    <div class="modal-body position-relative" style="min-height: 400px">
        <div class="d-flex align-items-center" *ngIf="loadingModuleCharges">
            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
            <span class="ml-2">Please wait while loading Charges ...</span>
        </div>
        <div class="mh-300 position-relative" *ngIf="!loadingModuleCharges && !moduleCharges?.length">
            <no-data [title]="'Charges'"></no-data>
        </div>
        <ng-container *ngIf="!loadingModuleCharges && moduleCharges?.length">
            <div class="alert alert-warning modal-alert" role="alert">
                <span *ngIf="selectedModule.packageType === packageType.MultiSelect">Please choose one or more items from the list below.</span>
                <span *ngIf="selectedModule.packageType === packageType.SingleSelect">Please choose only one item from the list below.</span>
            </div>
            <table class="table table-centered font-13 table-sm mb-0">
                <thead>
                    <tr>
                        <th class="border-top-0">Charge Details</th>
                        <th class="border-top-0" *ngIf="showRepeatType" style="width: 140px">Charge Group</th>
                        <th class="border-top-0" *ngIf="showRepeatType" style="width: 140px">Service Department</th>
                        <th class="border-top-0" *ngIf="showRepeatType" style="width: 140px">Charged Per</th>
                        <th class="border-top-0" style="width: 120px">Quantity</th>
                        <th class="border-top-0 text-center" style="width: 60px">Free</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of moduleCharges; trackBy: trackBy.moduleCharge; let i = index">
                        <td>
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [(ngModel)]="item.isSelected" (click)="onSelectCharge(item, $event)" [attr.id]="'checkbox-charge' + (i + 1)">
                                <label class="custom-control-label" [attr.for]="'checkbox-charge' + (i + 1)" [textContent]="item.chargeName"></label>
                            </div>
                        </td>
                        <td *ngIf="showRepeatType" [textContent]="item.chargeGroupName || '-'"></td>
                        <td *ngIf="showRepeatType" [textContent]="item.departmentName || '-'"></td>
                        <td *ngIf="showRepeatType" [textContent]="item.repeatTypeName || '-'"></td>
                        <td>
                            <input type="number" min="1" max="50" step="1" class="form-control form-control-sm" [(ngModel)]="item.quantity" placeholder="##" />
                        </td>
                        <td class="text-center">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [(ngModel)]="item.isFree" (click)="onSelectFree(item, $event)" [attr.id]="'checkbox-free' + (i + 1)" />
                                <label class="custom-control-label" [attr.for]="'checkbox-free' + (i + 1)"></label>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
    </div>
    <div class="modal-footer" *ngIf="!loadingModuleCharges && moduleCharges?.length">
        <nav class="d-flex align-items-center justify-content-between flex-wrap p-0" [class.w-100]="pagination.totalPages > 1">
            <p class="mb-0 font-13" *ngIf="pagination.totalPages > 1">
                <span class="text-dark">Page <span [textContent]="pagination.currentPage"></span> of <span [textContent]="pagination.totalPages"></span></span>
                <span class="ml-1">
                    <span>(Showing <span [textContent]="pagination.currentItems - moduleCharges.length + 1"></span> - <span [textContent]="pagination.currentItems"></span> of <span [textContent]="pagination.totalItems"></span> records)</span>
                </span>
            </p>
            <div class="d-flex align-items-center">
                <ngb-pagination *ngIf="pagination.totalPages > 1" class="pagination justify-content-end mr-1" [maxSize]="5" [rotate]="false" [ellipses]="false" [(page)]="pagination.pageIndex" [pageSize]="pagination.pageSize" (pageChange)="onNextPage(selectedModule)" [collectionSize]="pagination.totalItems">
                    <ng-template ngbPaginationPrevious><i class="fe-arrow-left"></i></ng-template>
                    <ng-template ngbPaginationNext><i class="fe-arrow-right"></i></ng-template>
                </ngb-pagination>
                <button type="button" class="btn btn-sm bg-white btn-white" (click)="onCloseModal();">Cancel</button>
                <button type="button" class="btn btn-sm btn-primary ml-1" (click)="onSubmitCharges(moduleCharges[0].modulesMasterId);">Submit</button>
            </div>
        </nav>
    </div>
</ng-template>