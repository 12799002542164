export class PharmacyPurchaseBill {
    pharmacyPurchaseHeaderId: number;
    productName: string;
    billNumber: string;
    batchNumber: string;
    billDate?: Date | string;
    //billDate: Date;
    expiryDate?: Date;
    billType: string;
    supplierId: number;
    supplierName: string;
    roleName: string;
    billAmount: number;
    overallDiscount: number;
    taxes: number;
    totalNetAmount: number;
    pharmacyPurchaseDetailId: number;
    serialNum: number;
    pharmacyProductId: number;
    quantity: number;
    returnQuantity: number;
    returnFreeQuantity: number;
    free?: number;
    purchaseRate: number;
    purchaseType: number;
    mrp: number;
    total: number;
    taxPerItem: number;
    taxAmount: number;
    discountPerItem: number;
    discountAmount: number;
    netAmount: number;
    barcode: string;
    pharmacyStockId: number;
    createdBy: number;
    createdByName: string;
    fromDate: Date;
    toDate: Date;
    genericName: string;
    categoryName: string;
    companyName: string;
    encryptedPharmacyStockId: string;
    amount: number;
    overallAmount: number;
    paidVia: string;
    paidType: string;
    availableQuantity: number;
    quantityIn: number;
    quantityOut: number;
    dueDate: Date;
    dueDays: number;
    isCreditClear: boolean;
    wareHouseName: string;
    returnDate: Date;
    returnNetamount: number;
    dueAmount: number;
    locationName: string;

    purRateIncludeAll: number;
    purRateIncludeAllUnit: number;
    pharmacyWareHouseId?: number;
    isIGST: boolean;
    isSGST: boolean;
    sgst?: number;
    cgst?: number;
    igst?: number;
    isSelected: boolean;
    paidOnDate: Date;
    pONumber: string;
    mrpTotal: number;
    amountToPay: number;
    grnNumber: string;
    poNumber: string;
    totalItems: number;
    isCancel: string;
    isCancelled: boolean;
}