import { AppConfig } from "@shared/services";

export class ApiResources {
    static readonly account = {
        base: "account",
        authenticate: "authenticate",
        refresh: "refresh-authentication",
        forgotPassword: "forgot-password",
        updatePassword: "change-password",
        fetch: "fetch",
        modifyStatus: "modify-status",
        lockStatus: "modify-locked-status",
        getMenus: "get-menus",
        modifyLocation: "modify-account-location",
        logOut: "web-logout",
        isNurse: "is-nurse",
        checkPasswordForEncounter: "check-password"

    };
    
    static readonly counselling = {
        base: "counselling",
        fetch: "fetch-counselling",
        fetchBasics: "fetch-basics",
        find: "find",
        patientInfo: "patient-info",
        modify: "modify",
        publish: "publish",
        delete: "delete",
        fetchAll: "fetch-all",
        modifyCounsellingPackageStatus: "modify-counselling-package-status",
        sendCounsellingEmail: "send-counselling-email",
        uploadPdf: "uploadPdf",
        counsellingReport: "counselling-report"
    };

    static readonly receipt = {
        base: "receipt",
        fetch: "fetch",
        insert: "insert",
        cancel: "cancel",
        fetchReceiptTypes: "fetch-receipt-types",
        fetchallreceipts: "fetch-all-receipts",
        updatePaytypes:"updatePaytypes"
    };

    static readonly PediatricItemMaster = {
        base: "Pediatric-Item-Master",
        fetch: "fetch-Pediatric-Item-Master",
        insert: "add-Pediatric-Item-Master",
        update: "update-Pediatric-Item-Master",
        delete: "delete-Pediatric-Item-Master",
        fetchOral: "fetch-Oral",
        insertOral: "add-Oral",
        addNonOral: "add-NonOral",
        fetchNonOral: "fetch-NonOral",
        insertInstruction: "add-Pediatric-Instruction",
        fetchInstruction: "fetch-Pediatric-Instruction"
    };

    static readonly feedback = {
        base: "feedback",
        add: "add",
        fetch: "fetch",
        addQuestion: "add-question",
        update: "update",
        delete: "delete",
        questionFetch: "fetch-question",
        fetchCount: "fetch-count"
    }



    static readonly queue = {
        base: "queue",
        fetch: "fetch",
        callout: "callout",
        checkIn: "check-in",
        accept: "accept",
        cancel: "cancel",
        complete: "complete",
        saveWebToken: "save-web-token",
        moveToQueue: "move-to-queue",
        mobike: "mobile-fetch"
    }

    static readonly discharge = {
        base: "discharge",
        fetchInstructions: "fetch-instructions",
        fetchStatus: "fetch-status",
        insert: "insert",
        update: "update",
        fetch: "fetch",

        dischargeinsert: "insert-discharge",
        dischargeupdate: "update-discharge",
        dischargefetch: "fetch-discharge"
    };

    static readonly finalBill = {
        base: "final-bill",
        fetch: "fetch",
        insert: "insert",
        cancel: "cancel",
        getBasics: "get-basics",
        sendPaymentLink: "send-payment-link",
        applyCopay: "apply-copay",
    };

    static readonly bedManagement = {
        base: "bed-management",
        fetchWards: "fetch-wards",
        fetchRooms: "fetch-rooms",
        fetchBeds: "fetch-beds",
        fetchAvailablityBeds: "fetch-beds-availability",
        fetchlistview: "fetch-beds-listview",
        findPatientDetailsOnBed: "find-patient-bed-details",
        countAvailableBeds: "count-available-beds",
        countBookedBeds: "count-booked-beds",
        countCleaningBeds: "count-cleaning-beds",
        countTotalBeds: "count-total-beds",
        fetchBedStatus: "bed-status-list",
    };

    static readonly chargeManagement = {
        base: "charge-management",
        masters: "masters",
        fetch: "fetch",
        insert: "insert",
        update: "update",
        fetchWithCharges: "fetch-with-charges",
        findChargeTemplate: "find-charge-template",
        fetchTemplateModules: "fetch-template-modules",
        fetchTemplateChargeCategories: "fetch-template-charge-categories",
        fetchLabCharges: "fetch-lab-charges",
        fetchScanCharges: "fetch-scan-charges",
        fetchServiceCharges: "fetch-service-charges",
        fetchOTCharges: "fetch-ot-charges",
        fetchRoomCharges: "fetch-room-charges",
        fetchDoctorCharges: "fetch-doctor-charges",
        fetchIPDoctorCharges: "fetch-ip-doctor-charges",
        fetchDoctorUnitCharges: "fetch-doctor-unit-charges",
        fetchPatientRegistrationCharges: "fetch-patient-registration-charges",
        assignChargeTemplate: "assign-charge-template",
        fetchWithChargesNew: "fetch-with-charges-new",
        fetchTemplateServiceCharges: "fetch-template-service-charges",
        fetchDoctorSpecialization: "fetch-doctor-specializations",
        findDoctorChargeTypes: "fetch-doctor-charge-type",
        updateSpecializationCharges: "update-Specilization"
    };

    static readonly package = {
        base: "package",
        fetch: "fetch",
        insert: "insert",
        update: "update",
        delete: "delete",
        productMasters: "fetch-Product-masters",
        fetchProducts: "fetch-Products",
        surgeryMasters: "fetch-surgery-masters",
        fetchSurgeries: "fetch-surgeries",
        metaData: "fetch-meta-data",
        deletePackageCharge: "delete-package-charge",
    };

    static readonly OTManagement = {
        base: "OTManagement",
        addOrUpdateFallsAssessment: "addOrUpdate-FallsAssessment",
        fetchFallsAssessment: "fetch-FallsAssessment",
        addOrUpdateMonitoringSheet: "addOrUpdate-MonitoringSheet",
        fetchMonitoringSheet: "fetch-MonitoringSheet",
        deleteMonitoringSheet: "delete-MonitoringSheet",
        addOrUpdatePatientShifting: "addOrUpdate-PatientShifting",
        fetchPatientShifting: "fetch-PatientShifting",
        addOrUpdatePostOperativeShiftingCriteria: "addOrUpdate-PostOperativeShiftingCriteria",
        fetchPostOperativeShiftingCriteria: "fetch-PostOperativeShiftingCriteria",
        addOrUpdateCautiBundle: "addOrUpdate-CautiBundle",
        fetchCautiBundle: "fetch-CautiBundle",
        addOrUpdatePainRatingScale: "addOrUpdate-PainRatingScale",
        fetchPainRatingScale: "fetch-PainRatingScale",
        deletePainRatingScale: "delete-PainRatingScale",
        addOrUpdatePostOperativeInstructions: "addOrUpdate-PostOperativeInstructions",
        fetchPostOperativeInstructions: "fetch-PostOperativeInstructions",
        fetchAldereScore: "fetch-AldereScore",
        addOrUpdateAldereScore: "addOrUpdate-AldereScore",
        addOrUpdateSurgicalInformation: "addOrUpdate-SurgicalInformation",
        fetchSurgicalInformation: "fetch-SurgicalInformation",
        fetchSurgicalSafetyChecklist: "fetch-SurgicalSafetyChecklist",
        addOrUpdateSurgicalSafetyChecklist: "addOrUpdate-SurgicalSafetyChecklist",
        fetchBloodLossEstimation: "fetch-BloodLossEstimation",
        addOrUpdateBloodLossEstimation: "addOrUpdate-BloodLossEstimation",
        fetchSurgicalNeedleCount: "fetch-SurgicalNeedleCount",
        addOrUpdateSurgicalNeedleCount: "addOrUpdate-SurgicalNeedleCount",
        addOrUpdateNurseHandoverChecklist: "addOrUpdate-nurseHandoverChecklist",
        fetchHandoverChecklist: "fetch-HandoverChecklist",
        addorUpdateOperationNotes: "addorUpdate-OperationNotes",
        fetchOperationNotes: "fetch-OperationNotes",
        addOrUpdateNursingChecklist: "addOrUpdate-NursingChecklist",
        fetchNursingChecklist: "fetch-NursingChecklist",
        fullOtReprot: "fetch-OtReprot",
        fetchAdmissionDetails: "fetch-AdmissionDetails"
    };

    static readonly packageModule = {
        base: "package-module",
        fetchLocations: "fetch-locations",
        fetchChargeCategories: "fetch-charge-categories",
        fetchChargeCategoriesWithTotal: "fetch-charge-categories-with-total",
        fetchModules: "fetch-modules",
        fetchTemplateModules: "fetch-template-modules",
        fetchModuleCharges: "fetch-module-charges",
        fetchChargeDetails: "fetch-charge-details",
        modify: "modify",
        view: "view",
        fetch: "fetch",
        fetchPackageDetails: "fetch-package-details",
        find: "find",
        enable: "enable",
        disable: "disable",
        delete: "delete",
        uploadDocument: "upload-package-document",
        fetchPackageDocuments: "fetch-package-document",
        fetchPackagesOfPatient: "fetch-package-of-patient",
        checkAppointmentItemPackageQuantity: "fetch-package-available-quantity",
        fetchServiceLinkCharges: "fetch-service-link-charges",
        detailReport: "detail-report"
    };

    static readonly serviceOrder = {
        base: "service-order",
        getAdmissionBasic: "get-admission-basic",
        totalPaid: "total-paid",
        insert: "insert",
        insertLabOnly: "insert-lab-only",
        update: "update",
        fetch: "fetch",
        fetchAdmission: "fetch-admission",
        automatic: "automatic",
        fetchCounsellings: "fetch-counsellings",
        applyCounsellings: "apply-counselling",
        isFirst: "is-first",
        addPackage: "add-package",
        getPreviousPackages: "fetch-previous-packages",
        dayCostTypeChange: "day-cost-type-change",
        updateSurgeryTime: "update-surgery-time",
        normalize: "normalize",
        cancelAll: "cancel-all",
        fetchProviderInvoice: "fetch-doctorvisit-invoice",
        addScansOnly: "insert-scan-only",
        stopNurseAddedService: "stop-nurse-added-service",
        fetchPatientTransferBeds: "fetch-patient-transfer-beds",
        fetchPharmacyPendingIndents: "fetch-pharmacy-pending-indents",
        fetchPharmacyIssuedIndents: "fetch-pharmacy-issued-indents",
        fetchPharmacyIndents: "fetch-pharmacy-indents"
    };

    static readonly menu = {
        base: "menu",
        insert: "insert",
        insertOnly: "insert-only",
        removeOnly: "remove-only",
        update: "update",
        fetch: "fetch",
        fetchButtons: "fetch-buttons",
        freeFetch: "free-fetch",
        relationships: "relationships",
        addButton: "add-button",
        roles: "roles",
        updateRelationships: "update-relationships"
    };

    static readonly auditlog = {
        base: "auditlog",
        fetch: "fetch",
        insert: "insert"
    };

    static readonly callhistorydata = {
        base: "callhistorydata",
        add: "add",
        fetch: "fetch",
        insert: "insert",
        update: "update",
        delete: "delete",
        fetchcallhistoryId: "fetchcallhistoryId"
    };


    static readonly feedbackDetails = {
        base: "feedbackdetails",
        add: "add",
        fetch: "fetch",
        insert: "insert",
        update: "update",
        delete: "delete",
        fetchfeedbackId: "fetchfeedbackId"
    };

    static readonly contactdetails = {
        base: "contactdetails",
        add: "add",
        fetchbyId: "fetchbyId",
        fetch: "fetch",
        insert: "insert",
        update: "update",
        delete: "delete",
    };

    static readonly exceptionLog = {
        base: "exception-log",
        fetch: "fetch",
        fetchLogRoute: "fetch-log-route",
        resolve: "resolve",
        fetchMethod: "fetch-method"
    };

    static readonly progressReport = {
        base: "progress-report",
        medicationsAsync: "medications-async",
        instructions: "fetch-instructions",
        insertMedication: "insert-medications",
        fetchMedication: "fetch-medications",
        fetchTimeline: "fetch-timeline",
        fetchAllTimeline: "fetch-all-timeline",
        fetchCounts: "fetch-counts",
        fetchTimelineDates: "fetch-timeline-dates",
        fetchMedicationTimeline: "fetch-medications-timeline",
        stopMedication: "stop-medication",
        takeMedication: "take-medication",
        fetchMedicationInfo: "fetch-medication-info",
        mealTypesAsync: "meal-type-async",
        updateMedication: "update-medication",
        deletemedication: "delete-medication"
    };

    static readonly progressReportLab = {
        base: "progress-report-lab",
        labsAsync: "labs-async",
        fetch: "fetch",
        updateStatus: "update-status"
    };

    static readonly patientInsurance = {
        base: "patient-insurances",
        add: "add",
        fetch: "fetch",
    };

    static readonly progressReportNote = {
        base: "progress-report-note",
        fetch: "fetch",
        insert: "insert",
        update: "update",
        delete: "delete"
    };

    static readonly nurseNote = {
        base: "nurse-note",
        fetch: "fetch",
        insert: "insert"
    };

    static readonly progressReportVitals = {
        base: "progress-report-vitals",
        fetch: "fetch",
        insert: "insert",
        update: "update",
        delete: "delete",
        fetchVitalType: "fetch-vital-type",
        fetchUnitType: "fetch-unit-type",
        fetchVitalData: "fetch-vital-data",
        fetchVitalFrequency: "fetch-vital-Frequency",
        insertVitalFreqRecord: "insertFreqRecord",
        fetchemr: "fetch-vitals-emr",
        deleteVitalEmr: "delete-emr-vitals"
    };

    static readonly progressReportDiet = {
        base: "progress-report-diet",
        fetch: "fetch",
        insert: "insert",
        update: "update",
        delete: "delete",
        stopDiet: "stop-diet"
    };


    static readonly progressReportAssessments = {
        base: "progress-report-assessments",
        fetch: "fetch",
        insert: "insert",
        update: "update",
        delete: "delete",
        fetchAssessmentType: "fetch-assessment-type"
    };

    static readonly resources = {
        base: "resources",
        roleAccounts: "role-accounts",
        specializations: "specializations",
        services: "services",
        timeZones: "time-zones",
        countries: "countries",
        patients: "patients",
        ScanPatients: "scan-patients",
        surgeryTypes: "surgeryTypes",
        provider: "provider",
        providerWithCharges: "provider-with-charges",
        relationships: "relationships",
        insuranceCompanies: "insurance-companies",
        tpaCompanies: "tpa-companies",
        defaultChargeCathgory: "default-chargecategory",
        providerLocations: "provider-locations",
        providers: "providers",
        providerOnly: "provider-only",
        drugs: "drugs",
        generalAdvice: "general-advices",
        icdCode: "icd-codes",
        practiceLocations: "practice-locations",
        lookups: "lookups",
        labOrder: "lab-order",
        labOrders: "lab-orders",
        radiology: "radiology",
        lookupValues: "lookup-values",
        documentCategories: "document-categories",
        role: "roles",
        rolesWithOutPatient: "RolesWithOutPatient",
        fetchUsers: "fetch-users",
        fetchNurses: "fetch-nurses",
        fetchAllUsers: "fetch-all-users",
        fetchAdminSuperAdmin: "fetch-admin-super-admin-users",
        languages: "languages",
        questions: "questions",
        fetchUsersOnly: "fetch-users-only",
        fetchPatient: "fetch-patients",
        departments: "fetch-providerdepartments",
        department: "department",
        pharmaDepartment: "pharmacy-department",
        pharmacyCategories: "fetch-pharmacy-categories",
        pharmacyCompanies: "fetch-pharmacy-companies",
        pharmacyProducts: "fetch-pharmacy-products",
        pharmacyProduct: "fetch-pharmacy-product",
        homeopathyDrugs: "homeopathy-drugs",
        instructions: "instructions",
        problemList: "problem-list",
        familyMembers: "family-members",
        radiologyOrders: "radiology-orders",
        receiptReports: "receipt-reports",
        encounterLookups: "encounter-lookups",
        encounterLookupValues: "encounter-lookup-values",
        allAccountWithoutCurrentRoleId: "account-without-passed-role",
        patientDoctorsOnly: "patient-doctors-only",
        logType: "log-types",
        patientserviceslogType: "patient-services-log-types",
        pharmacyLogType: "pharmacy-log-types",
        inventoryLogType: "inventory-log-types",
        dischargeStatus: "discharge-statuses",
        labLogType: "lab-log-type",
        scanLogType: "scan-log-type",
        labName: "lab-name",
        providername: "fetch-provider",
        widgetTypes: "widget-types",
        payType: "pay-type",
        allPayTypes: "all-Pay-types",
        widgets: "widgets",
        states: "states",
        cities: "cities",
        packages: "packages",
        packageModules: "package-modules",
        moduleTypes: "module-types",
        packageTypes: "package-types",
        floors: "floors", // with login location
        allFloors: "all-floors",
        wards: "wards",
        allWards: "all-wards",
        rooms: "rooms",
        allRooms: "all-rooms",
        beds: "beds",
        allBeds: "all-beds",
        bedStatus: "bed-status",
        allScanMachines: "all-scan-machines",
        allScanTests: "all-scan-tests",
        scanMachineFilterMachines: "scan-machine-filter-machines",
        scanMachineFilterTests: "scan-machine-filter-tests",
        allLocationScanTests: "all-location-scan-tests",
        filtersData: "fetch-beds-filters-data",
        fetchImages: "image",
        fetchGeneralWardNurses: "fetch-general-ward-nurses",
        fetchShifts: "fetch-shifts",
        fetchSlots: "fetch-slots",
        fetchInsuranceTypes: "fetch-insurance-types",
        patientInsurances: "fetch-patient-insurances",
        retailStore: "fetch-retail-store",
        patientAdmissionDoctorOnly: "patient-admission-doctors-only",
        demandProducts: "demand-products",
        patientAppointmentDoctorOnly: "patient-appointment-doctors-only",
        wareHouse: "fetch-ware-house",
        inventoryWareHouse: "fetch-Inventory-warehouse",
        mealType: "meal-types",
        providerPracticeLocation: "provider-practice-location",
        admissionProviders: "admission-providers",
        problems: "problems",
        goals: "goals",
        objectives: "objectives",
        interventions: "interventions",
        locations: "locations",
        hwcPatient: "hwc-patients",
        salutation: "salutation",
        nationality: "nationality",
        religion: "religion",
        gender: "gender",
        maritalStatus: "maritalStatus",
        allHWCPatients: "all-hwc-patients",
        widgetCountTypes: "widget-count-types",
        appointmentTypes: "appointment-types",
        referredByNames: "referredByNames",
        referredDoctors:"referredDoctors",
        labDepartment: "lab-department",
        labMaster: "lab-master",
        labVacutainer: "lab-vacutainer",
        labExternalAgencies: "lab-external-agencies",
        labActiveExternalAgencies: "lab-external-agencies-active",
        scanMachines: "scan-machine-availability",
        fetchScanMachineTest: "fetch-scan-machine-test",
        scanClassification: "fetch-scan-classification",
        getAncNumber: "get-anc-number",
        getPatientBMI: "get-patient-bmi",
        scanSubClassification: "fetch-scan-sub-classification",
        scanSubClassificationTests: "fetch-sub-classification-tests",
        scanMachineMaster: "scan-machine-master",
        fetchAdminAndSupportAssignee: "fetch-admin-and-support-users-assignee",
        providerDepartment: "providerDepartment",
        activePatients: "active-patients",
        locationMapAccount: "fetch-account",
        locationMapAccountDynamic: "fetch-account-dynamic",
        providerAccountLocationMap: "fetch-provider-account",
        doctorUnitMasterId: "doctor-unit-masterId",
        scanProviderLocationBased: "fetch-location-scan-provider",
        providerSpecializations: "provider-specializations",
        inventoryProduct: "fetch-inventory-products",
        notificationTypes: "notification-types",
        payCategories: "fetch-pay-category",
        menuAccessedRoles: "menuAccessedRoles",
        consultationType: "consultation-type",
        availabilityChargeType: "availability-charge-type",
        searchPatientByFilter: "appointment-patient-filter",
        providerAvailability: "provider-availability",
        receiptAreaTypeId: "receipt-area",
        receiptAreaTypeIds: "receipt-area-id",
        partsOfDay: "parts-of-day",
        howDidYouKnow: "how-did-you-know",
        education: "education",
        occupation: "occupation",
        activeStatus: "active-status",
        doctorSpecializations: "doctor-specialization",
        locationProviders: "location-provider",
        locationSpecializations: "location-specializations",
        providerSchdule: "provider-schdule",
        specializationForProviderAvialability: "specialization-provider-availability",
        pathologyProviders: "pathology-providers",
        encounterType: "encounter-type",
        encounterOrderTemplates: "encounter-order-templates",
        locationScanTest: "location-scan-test",
        locationScanMachine: "location-scan-machine",
        fetchScanMachinesTests: "fetch-scan-machines-tests",
        getProfileImage: "get-profile-image",
        fetchScanTestCategoryTypes: "fetch-scan-test-category-types",
        chargeCategory: "charge-category",
        locationForPractice: "locations-practice",
        practiceLocationsForProvider: "practice-provider-location",
        scanAvailabilityStatus: "scan-availability-status",
        scanAvailabilityReason: "scan-availability-reason",
        providerBreak: "provider-break",
        providerBlock: "provider-block",
        documentType: "document-type",
        visitType: "visit-type",
        scanScrollResult: "scan-scroll-result",
        scanDoctors: "scan-doctors",
        chargeCategoryLocation: "charge-category-location",
        otRoomMaster: "ot-room-master",
        surgeryNames: "surgey-names",
        providersForOt: "provider-names",
        accountWithExpectedRole: "account-with-passed-role",
        idProofNames: "idproof-names",
        fetchPatientNameMobileUmr: "patient-name-mobile-Umr",
        anaesthesiaTypes: "anaesthesia-types",
        surgeris: "surgeries",
        locationSurgeries: "location-surgeries",
        fetchOTRoomSurgery: "fetch-Otroom-surgeries",
        fetchRooms: "fetch-rooms",
        fetchCaseTypes: "fetch-case-types",
        fetchEmergencyCaseTypes: "fetch-emergencyCase-types",
        discountNames: "discount-module",
        counsellingTypes: "counselling-types",
        fetchAdmissionPayTypes: "admission-pay-type",
        SessionType: "session-type",
        doctorSpecializationwithAllConsultations: "provider-specialization-all-consulation",
        checkNewPatientAppointment: "check-new-patient-appointment",
        referralDoctor: "referral-doctor",
        visitTypes: "visit-types",
        inPatients: "in-patients",
        uniqueOTRooms: "unique-otRooms",
        doctorWeek: "doctor-week",
        outPatients: "out-patients",//
        healthCard: "health-card",
        appointments: "appointments",
        fetchSurgeries: "unique-surgeries",
        ambulances: "ambulances",
        drivers: "driver-details",
        reasons: "reasons",
        authorityMaster: "authority",
        doctorUnit: "doctorUnit",
        providerSignature: "provider-signature",
        consultantDoctor: "consultant-doctors",
        medicationFrequency: "medication-frequency",
        lookupValueOnLookupName: "lookup-value-on-name",
        dietSlotvalueOnDietSlotname: "Dietslot-value-on-name",
        registeredPatients: "registered-patients",
        fetchProviderLocation: "fetch-provider-location",
        scanDistinctOutPatients: "scan-distinct-out-patients",
        scanBulkRCPatients: "scan-bulk-reschedue-patients",
        dietGuidlines: "diet-guidlines",
        dietTypes: "diet-type",
        patientNameAppointment: "patient-name-appointment",
        doctorAppointmentNotice: "doctor-appointment-notice",
        scanAppointmentNotice: "scan-appointment-notice",
        emergencyDetails: "emergency-detail",
        patientRegistrationCharges: "patient-registration-charge",
        practice: "practice",
        enounterLookUp: "encounter-lookup",
        encounterAllLookupvalues: "encounterlookupvalues",
        scantestMachines: "fetch-scan-test-machines",
        admissionPatients: "fetch-admission-patients",
        admissionActivePatient: "admission-active-patient",
        parentPatient: "fetch-parent-patients",
        getWareHouseRetailStore: "get- warehouse - retailstore",
        getWareHouses: "fetch-warehouse",
        lWareHouses: "location-warehouses",
        retailStoreWare: "warehouse-retail",
        encounterTemplates: "fetch-template-header",
        modulesMaster: "fetch-modules-master",
        fetchAllLabs: "fetch-all-labs",
        providersLocation: "providers-location",
        providersBasedOnSpecialization: "specialization-provider",
        locationChargeTemplate: "location-charge-template",
        chargeTemplates: "charge-templates",
        dietConditions: "diet-conditions",
        patientsWithUmrAndMobile: "patients-WithUmr-AndMobile",
        allergies: "allergies",
        fetchPotentialTypes: "fetch-potential-types",
        callCenterPatientsWithUmrAndMobile: "callcenter-patients-withUmr-andMobile",
        allpatientswithUmrandMobile: "all-patients-withUmr-andMobile",
        fetchRoleDepartment: "fetch-role-department",
        products: "products",
        fetchautomatictype: "fetch-automaticType",
        fetchCounsellingNumbers: "fetch-counsellings",
        dietConditionTypes: "fetch-diet-condition-types",
        inPatientsWithUmrAndMobile: "in-patients-WithUmr-AndMobile",
        allNonTransitRooms: "non-transit-rooms",
        fetchRoomChargeCategory: "room-charge-category",
        fetchNonTransitRoomBeds: "non-transit-room-beds",
        fetchLocationAdmissions: "location-admissions",
        dietPlanTypes: "diet-plan-type",
        fetchICDValues: "fetch-icd-values",
        cancelAdmissionPatient: "cancel-admission-patient",
        admissionReferralDoctor: "admission-referral-doctor",
        fetchAdmissionPatientNameMobileUmr: "patient-admission-name-mobile-Umr",
        patientsForLabs: "patients-for-labs",
        admissionNoForCancelledFinalBill: "admissionno-finalbill-report",
        billNumberForCancelledFinalBill: "billnumber-finalbill-report",
        fetchSurgeryTypes: "fetch-surgery-types",
        patientLogTypes: "fetch-patient-logtypes",
        usersByRole: "fetch-users-by-role",
        dailyBasesOutPatients: "daily-bases-out-patients-resource",
        bookingVisitsPatients: "booking-visits-patients",
        specializationUnit: "specialization-unit",
        fetchAdmissionNo: "fetch-AdmissionNo",
        fetchWereHouse: "fetch-all-products",
        fetchIssueNumber: "fetch-issue-numbers",
        consultantNames: "fetch-ProviderName",
        specializationNames: "fetch-specialization",
        fetchTpa: "fetch-tpa",
        fetchChargeCategoryNames: "fetch-chargecategory",
        fetchPatientNames: "fetch-patientNames",
        fetchVisitTypeNames: "fetch-visittypename",
        fetchProvidernames: "fetch-providernames",
        fetchLabStatus: "fetch-labstatus",
        fetchConsentForms: "fetch-consent-forms",
        sameDaySessionAppointments: "same-day-session-appointment",
        outPatientLegends: "out-patient-legend-colors",
        fetchServices:"fetch-services",
        fetchProviderByLocation: "fetch-provider-by-location",
        fetchPatientNameAppointmentNo: "patient-appointment-name-appointmentno",
        fetchReportName: "fetch-report-name",
        fetchProviderMaster:"fetch-providers",
        fetchPublicIp: "fetch-public-ip",
        fetchVendorNames: "fetch-vendornames",
        fetchInsuranceDetails: "fetch-InsuranceDetails",
        fetchGrn: "fetch-Grn",
        fetchInvoice: "fetch-invoice",
        findEncounterLookUpValues:"find-Encounterlookupvalues",
        defaulImgUrl: "default-Img-url",
        fetchCounsellingAllNumbers: "fetch-all-counsellings",
    };


    static readonly pharmacyIndent = {
        base: "pharmacy-indent",
        findStocks: "find-stocks-by-productId",
        add: "add-pharmacy-indent",
        fetchPharmacyIndentHdr: "fetch-pharmacy-indent-hdr",
        fetchPharmacyIndentDtl: "fetch-pharmacy-indent-dtl",
        delete: "delete-pharmacy-indent",
        delivery: "on-agree-delivered"
    }

    static readonly services = {
        base: "services",
        fetch: "fetch",
        find: "find",
        add: "add",
        update: "update",
        modifyStatus: "modify-status",
        delete: "delete"
    };

    static readonly providerEncounter = {
        base: "providerEncounter",
        fetch: "fetch",
        updatelookupvalue: "update-lookup-value",
        add: "add",
        delete: "delete",
        providerEncounter: "fetch-provider-encounter",
        generaAdvices: "fetch-general-advices",
        icdCodes: "fetch-icd-codes",
        laborderValues: "fetch-lab-order-values",
        radiologyValues: "fetch-radiology-values",
        allergies: "fetch-allergies",
        reactions: "fetch-reactions",
        hospitalizations: "fetch-hospitalizations",
        initials: "fetch-initials",
        pCares: "fetch-pcares",
        sites: "fetch-sites",
        vaccines: "fetch-vaccines",
        unassignAllGeneralAdvices: "update-unassign-all-general-advices",
        unassignAllIcdCodes: "update-unassign-all-icd-codes",
        unassignAllLabOrderValue: "update-unassign-all-lab-order-value",
        unassignAllRadiologyValue: "update-unassign-all-radiology-value",
        unassignAllAllergies: "update-unassign-all-allergies",
        unassignAllReactions: "update-unassign-all-reactions",
        unassignAllHospitalization: "update-unassign-all-hospitalization",
        unassignAllInitials: "update-unassign-all-initials",
        unassignAllPCares: "update-unassign-all-pcare",
        unassignAllSites: "update-unassign-all-site",
        unassignAllVaccines: "update-unassign-all-vaccine",
    };

    static readonly radiology = {
        base: "radiology",
        update: "update",
        add: "add",
        fetch: "fetch",
        delete: "delete"
    };

    static readonly patients = {
        base: "patients",
        fetch: "fetch",
        find: "find",
        profile: "profile",
        add: "add",
        getRaw: "get-raw",
        update: "update", ///  master/patient/edit /update
        updateProfileImage: "update-profile-image",
        modifyStatus: "modify-status",
        lockStatus: "lock-status",
        manualVerify: "manual-verify",
        fetchFamilyMembers: "fetch-family-member",
        addMembers: "add-member",
        updateMembers: "update-family-member",
        deleteMembers: "delete-family-member",
        modifyRegistrationCharge: "modify-registration-charge",
        fetchRegistrationCharge: "fetch-registration-charge",
        applyRegistrationCharge: "apply-registration-charge",
        fetchRegistrationBill: "fetch-registration-bill",
        fetchRegistrationBillWithRelative: "fetch-registration-bill-with-relative",
        addUnidentifiedPatient: "add-unidentified-patient", /// master /patient/ add unidentify
        patientRegistrationCharges: "patient-registration-charges",
        updatePatientDetails: "update-patient-details",
        updatePatientMultipleFormsDetails: "updatePatientMultipleForms",
        fetchANCCard: "fetch-anc-card",
        addBabyPatient: "add-baby-patient",
        patientTimeline: "patient-timeline",
        fetchpatientwallet: "fetch-patient-wallet",
        addpatientwallet: "add-patient-wallet",
        fetchBabyDetails: "fetch-baby-details",
        fetchPatientRelativeDetail: "find-patient-relative-detail",
        profileCommonWidget:"profile-common-widget",
        fetchPatientFirstDoctor: "fetch-patient-first-doctor",
        fetchPatientDetails:"fetch-opd-patient-details"
    };

    static readonly practices = {
        base: "practices",
        fetch: "fetch",
        find: "find",
        add: "add",
        update: "update",
        fetchPracticeInfo: "fetch-practice-info",
        practicelocationinfo: "fetch-practice-location-info"
    };

    static readonly report = {
        base: "reports",
        fetch: "fetch",
        inPatients: "in-patients",
        outPatients: "out-patients",
        dashboardInPatient: "dashboard-in-patients",
        dashboardOutPatient: "dashboard-out-patients",
        receiptReports: "receipt-reports",// ip/ou/for employee revenue
        indentInventoryReports: "indent-inventory-reports",
        indentInventoryPurchaseBillReports: "indent-inventory-purchase-bill-reports",
        indentInventoryStockReports: "indent-inventory-stock-reports",
        pharmacyBillReport: "pharmacy-bill-reports",/// pharma employee revenue
        employeeRevenueReport: "employee-revenue-reports",
        pharmarcySalesReport: "pharmacy-sales-reports",
        dailySalesReport: "daily-sales-reports",
        patientPharamcyReport: "patient-pharmacy-reports",
        pharamcyPurchaseReport: "pharmacy-purchase-reports",//pharma_purcharse_report4warehouses
        productPurchaseReport: "product-purchase-reports", //not in use.
        // pharmarcyStockReport: "pharmacy-stock-reports",
        pharmacyRetailStockReport: "fetch-pharmacy-retail-stock-report",
        pharmarcyStockReport: "fetch-pharmacy-stock-report",
        doctorRevenueReport: "doctor-revenue-reports",
        pharmacyIndentIssueReport: "pharmacy-indent-issue-reports",
        productSaleReport: "product-sale-reports",
        sendFinalBillEmail: "send-final-bill-email",
        updatePharmacyPurchaseBill: "update-pharmacy-purchase-bill", //not in use
        productProfitReport: "product-profit-report",
        patientRegistrationReport: "patient-registration-report",/// revenue-patient reg
        doctorRevenueLocationReport: "doctor-revenue-location-reports",
        employeeRevenueLocationReport: "employee-revenue-location-reports",//  for employee revenue
        cancelAppointments: "cancel-apppointments-with-refund-details",
        patientApppointments: "patient-apppointments",
        transactionReport: "transaction-report",
        newPatient: "new-patient-report",
        detailedEmployeeRevenueReport: "detailed-revenue-report",
        cancelRefundReceipt: "cancel-refund-receipt",
        opDailyGrossCollection: "op-daily-gross-collection",
        scheduledAppointment: "providers-appointment-slot",
        loginUserCollection: "login-userwise-collection",
        loginUserCollectionDetail: "login-userwise-collection-detail",
        userCollection: "user-collection",
        dashboardFollowUpPatients: "dashboard-followUp-patients",
        dashboardOnlinePatients: "dashboard-online-patients",
        dashboardWalkin: "dashboard-walkIn-appointmnet",
        dashbardNewAppointment: "dashboard-new-appointment",
        dashbardNewPatientAppointment: "dashboard-newpatients-appointmnet",
        dashboardOTAppointment: "dashboard-ot-appointmnet",
        admissionFormReport: "admission-form-report",
        fetchAllApts: "all-appointments",
        scheduledScanSlots: "scheduledScanSlots",
        misFetchReport: "mis-fetch-report",
        fetchAdmission: "all-admissions",
        newDoctorDetailRevenue: "new-doctor-detail-revenue",
        labnewTATReport: "lab-tat-new-report",
        labMonthlyCount: "lab-monthly-count",
        labSampleCollectionCount: "lab-sample-collection-count",
        consultationWiseReport: "consultation-wise-report",
        admissionDepartment: "admission-department",
        consultantStatistics: "consultant-statistics",
        medicationCountReport: "medication-count-report",
        labDetailReport: "lab-detail-report",
        expiryStockReport: "expiry-stock-report",
        billDiscount: "bill-discount",
        fetchReminders: "fetch-reminders",
        billDailyReport: "bill-daily-report",
        deptWiseAdmissionCount: "dept-wise-admission-count",
        fetchRetailIssueReport: "retail-indent-report",
        avgStayWardWiseReport: "avg-stay-ward-wise-report",
        callCenterReport: "call-center-report",
        labRevenueReport: "lab-revenue-report",
        CallCenterAppointments: "call-center-appointment",
        doctorAppointmentMonthReport: "doctor-appointment-month-report",
        doctorCapacityMonthReport: "doctor-capacity-month-report",
        servicesFinalBillReport: "services-finalbill-report",
        moduleWiseMonthlyReport: "modules-wise-montly-report",
        previousMonthModulesMessage: "previous-month-module-message",
        patientAllModulesReport: "patient-all-modules-report",
        eddCounsellingReport: "edd-counselling-report",
        patientreport: "patient-reports",
        eddAppointmentsGreaterThanTwentyTwoReport: "edd-appointments-greaterthan-twenty-two-weeks-report",
        doctorCapacityReport: "doctor-capacity-report",
        fetchwarehouse: "fetch-ware-house",
        fecthBillOverRideComments: "fetch-bill-over-ride-comments",
        //fecthPatientBillOverRideComments: "fetch-bill-over-ride-comments",
        fetchDischargedAdmissionBill: "fetch-discharged-admission-bill",
        cancelAdmission: "cancel-admission",
        cancelAdmissionReport: "cancel-admission-report",
        cancelFinalBillReport: "cancel-finalbill-report",
        issueIndentTrackReport: "issue-indent-track-report",
        ipProvisionReport: "fetch-ip-provision-report",
        dailyBasesOutPatientReport: "fetch-daily-bases-op-report",
        statusReport: "fetch-status-report",
        bookingVisitsReport: "booking-visits-report",
        ipBillDiscount: "ip-bill-discount-report",
        fetchconsultantWiseVisitPatientCount: "fetch-consultantwise-visit-patient-count",
        visitCreationTracking: "visit-creation-tracking",
        insuranceAgingReport: "insurance-aging-report",
        fetchConsultantWiseBedOccupancyCount: "fetch-consultantwise-bed-occupancy-count",
        patientOutStanding: "patient-outstanding-report",
        consultantOpdWaitingDetail: "consultant-opd-waiting-detail-report",
        timeMotionReport: "time-motion-report",
        servicesReports: "services-report",
        scanMISReport:"scan_mis-report",
        ipBillDiscountReport: "ip-bill-discount-report",
        labMISReport: "lab-mis-report",
        medicationprescribedVsSaleReport: "medication-prescribed-vs-sale-report",
        retailNonMovingStockReport: "retail-nonmoving-stock-report",
        appointmentMISReport: "appointment_mis-report",
        insuranceBillDiscount: "insurance-bill-discount-report",       
        counsellingStatus: "counselling-status",
        fetchPharmacyClosingStock: "fetch-pharmacy-closing-stock",
        patientPharmacyScheduleDrug: "patient-pharmacy-schedule-drug-reports",
        poVsGRNReport:"po-vs-grn-report"
    };

    static readonly providers = {
        base: "providers",
        register: "signup",
        profile: "find",
        update: "update",
        fetch: "fetch",
        find: "find",
        getRaw: "get-raw",
        add: "add",
        approve: "approve",
        reject: "reject",
        modifyProfile: "modify-provider-profile",
        updateProfileImage: "update-profile-image",
        findSettings: "find-provider-settings",
        modifySettings: "modify-provider-settings",
        updateProfile: "update-profile",
        lockStatus: "lock-status",
        fetchProviderMedications: "fetch-provider-medications",
        assignProviderRoom: "modify-provider-consultation-room",
        fetchProviderRoom: "fetch-provider-consultation-room",
        modifyStatus: "modify-status",

    };

    static readonly application = {
        base: "application",
        getAppConfig: "get-app-configuration",
        sendOtp: "send-otp",
        encode: "encode",
        decode: "decode",
        version: "version"
    };

    static readonly providerLocations = {
        base: "provider-locations",
        fetchSlots: "fetch-slots",
        fetchSlotsOnly: "fetch-slots-only",
        fetch: "fetch",
        fetchAvailabilities: "fetch-availabilities",
        modify: "modify",
        delete: "delete",
        modifyStatus: "modify-status",
        fetchVisitTypes: "fetch-visit",
        fetchVisitTypeIp: "fetch-visit-ip",
        fetchChargeType: "fetch-charge-type",
        modifyOperationAvailability: "modify-operation-availability",
        fetchOperationAvailability: "fetch-operation-availability",
        fetchNewSlotsOnly: "fetch-new-slots",//
        fetchAvailabilityCharges: "fetch-charge-types",
        fetchChargesBasedonChargeType: "fetch-charges-basedon-chargetype",
        fetchSlotsForMultipleDates: "fetch-slots-for-multipledays",
        fetchAvailabilityVisitTypes: "fetch-visit-types",
        fetchdurationbasedonvisitType: "fetch-duration-basedon-visitType",
        fetchSessionSlots: "fetch-session-slots"
    };

    static readonly appointments = {
        base: "appointments",
        getRaw: "get-raw",
        getLatest: "get-latest",
        fetch: "fetch-all",
        fetchNew: "fetch-new",
        payAddAppointment: "pay-add-appointment",
        fetchExistingPatients: "fetch-existing-patients",
        checkForFollowUp: "check-follow-up",
        add: "add",
        find: "find",
        quickSchedule: "quick-schedule",
        multiQuickSchedule: "multi-quick-schedule",
        findAppointment: "find-appointment",
        reschedule: "reschedule",
        updatePaymentStatus: "update-payment-status",
        cancel: "cancel",
        updatePatientType: "update-patient-type",
        fetchSupportAppointment: "fetch-support-appointment",
        updateLog: "update-support-appointment-log",
        addAssignee: "update-support-assignee",
        logCount: "fetch-log-count",
        findPreviousAppointmentMedications: "find-previous-medications",
        checkIn: "check-in",
        checkOut: "check-out",
        fetchPatients: "fetch-patients",
        fetchDoctorSpecialization: "fetch-doctor-specialization",
        fetchForInvoice: "fetch-for-invoice",
        cancelRefund: "appointment-cancel-amount-refunding",
        initiatePayment: "initiate-payment",
        findPatientAppointmentDate: "find-patient-appointmentDate",
        fetchDoctorSpecializationOp: "fetch-doctor-specialization-op",
        fetchSessionDoctors: "fetch-session-doctors",
        fetchAppointmentSchedulePatients: "fetch-appointment-schedule-patients",
        getPaymentData: "get-payment-data",
        updatePartialPayment: "update-partial-payment",
        fetchView: "fetch-view",
        cancelAll: "cancel-all",
        rescheduleAll: "reschedule-all",
        sendSms: "sendSms",
        getAppointmentCount: "get-appointments-count",
        fetchDoctorSession: "fetch-doctor-session",
        bookAppointment: "book-appointment",
        sendPaymentlink: "send-paymentlink",
        checkFutureAptCnt: "check-future-apt-count",
        isAppointmentExist: "is-appointment-exist",
        fetchAppointmentDashboard: "appointment-dashboard",
        fetchAppointmentCount: "appointment-count",
        fetchAncAppointments: "fetch-anc-appointmetnts",
        updateEncounterStattus: "update-EncounterStatus",
        fetchTatAppointments: "fetch-tat-appointmetnts",
        fetchFutureAppointments: "fetch-future-appointments",
        fetchAllAppointments: "fetch-all-appointments"
    };

    static readonly admissions = {
        base: "admissions",
        fetch: "fetch",
        quickSchedule: "quick-schedule",
        modify: "modify-admission",
        find: "find",
        findAdmission: "find-admission",
        cancel: "cancel",
        fetchWards: "fetch-wards",
        fetchPriorities: "fetch-priorities",
        fetchRooms: "fetch-rooms",
        fetchBeds: "fetch-beds",
        checkForFollowUpForIp: "check-follow-up-for-ip",
        updateDischargeDate: "update-discharg-date",
        raiseBedChangeRequest: "raise-bed-change-request",
        addTransferRequest: "insert-admission-transfer-request",
        fetchTransferRequest: "fetch-admission-transfer-request",
        acceptAdmissionTransferRequest: "accept-admission-transfer-request",
        rejectAdmissionTransferRequest: "reject-admission-transfer-request",
        fetchScheduledAdmission: "scheduled-admission",
        fetchChargeConfiguration: "charge-configuration",
        modifyChargeConfigurationStatus: "modify-charge-configuration-status",
        fetchIPInformation: "fetch-ip-information",
        fetchUsedPackages: "fetch-used-packages",
        fetchspeciallization: "fetch-specialization",
        updatecasetype: "update-case-type",
        fetchalltransferrequestpatients: "fetch-all-transferrequest-patients",
        fetchIpFromAdmissionNo: "fetch-ip-from-admission-no",
        addRoomChange: "add-ip-room-change",
        fetchChangedRoomData: "changed-room-data",
        releaseRoom: "release-ip-room-change",
        admissionsWithoutDischarge: "fetch-admissions-without-discharge",
        fetchinsurance: "fetch-Insurance",
        updateInsurance: "update-Insurance",
        updateInsuranceToCash: "update-InsuranceToCash",
        fetchAdmissionSlip: "fetch-admissionslip",
        fetchDoctorUnit: "fetch-doctor-unit",
        fetchDischargeRegister: "discharge-admission",
        fetchReadyForDischarge: "ready-for-discharge"
    };

    static readonly appointmentTransactions = {
        base: "appointment-transactions",
        fetch: "fetch",
        find: "find",
        earning: "get-provider-earning",
        fetchRefund: "fetchRefund",
        fetchPayout: "fetchPayout",
        fetchPayoutDetails: "fetchPayoutDetails",
        settlement: "settlement",
        fetchAppointments: "fetchAppointments",
        fetchProviderTransactions: "fetch-provider-transactions",
        paymentDocuments: "upload-patient-document",
        update: "update-payment-document",
        fetchDocuments: "fetch-payment-documents",
        delete: "delete"
    };

    static readonly providerSpecialization = {
        base: "provider-specializations",
        fetch: "fetch",
        add: "add",
        update: "update",
    };

    static readonly providerServices = {
        base: "provider-services",
        fetch: "fetch",
        modify: "modify"
    };

    static readonly encounters = {
        base: "encounters",
        find: "find",
        add: "add",
        findDashboard: "find-encounter",
        findFullTranscript: "find-full-transcript",
        modify: "modify",
        update: "update",
        delete: "delete",
        findEncounter: "find-previous-encounter",
        vitals: "fetch-vitals"
    };

    static readonly internalMedicine = {
        base: "internal-medicine",
        find: "find",
        findPrescription: "find-prescription",
        findFullTranscript: "find-full-transcript",
        findDashboard: "find-dashboard",
        modify: "modify",
        skin: "save-skin",
        findInternalMedicine: "find-internal-medicine"
    };

    static readonly documents = {
        base: "patient-documents",
        fetch: "fetch",
        upload: "upload",
        update: "update",
        delete: "delete",
        downloadfetch: "downloadfetch",
        viewFile: "viewFile",
        downloadFile: "download-file-ftp",
        downloadFileGet: "download-file-ftp-get",
        downloadpPatientDocumnet: "download-patient-documnet",
        downloadPastObDocument: "download-pastob-document",
        uploadtemplatedocuments: "upload-template-documents",
        fetchtemplatedocuments: "fetch-template-documents",
        fetchTemplateDocumentsWithPatientId: "fetch-template-documents-with-patientid",
        "changetemplatedocumentsstatus":"change-template-documents-status"
    };


    static readonly behavioralHealth = {
        base: "behavioral-health",
        find: "find",
        findDashboard: "find-dashboard",
        findPrescription: "find-prescription",
        modify: "modify",
        findFullTranscript: "find-full-transcript"
    };

    static readonly obEncounter = {
        base: "ob-encounter",
        find: "find",
        findDashboard: "find-dashboard",
        findPrescription: "find-prescription",
        modify: "modify",
        findFullTranscript: "find-full-transcript",
        calculateEDD: "calculate-edd",
        calculateGA: "calculate-ga",
        addAncCard: "add-ancCardGeneration",
        fetchANC: "fetch-ancCard",
        modifyStatus: "modify-ancStatus",
        fetchOrderPrescription: "fetch-order-prescription",
        completedEncounter: "encouter-completed",
        getIcdValues: "fetch-icdValues",
        findOb: "find-ob",
        fetchCrossConsultation: "fetch-cross-consultation",
        labReports: "ob-lab-reports",
        findVisitNo: "find-ANC-Visit",
        addOpiion: "add-opinion",
        fetchANCCard: "fetch-anc-card",
        fetchFullTrascript: "fetch-list-fulltrascript",
        fetchLabParameters: "fetch-lab-parameters",
        findEncounterData: "find-encounter",
        findFutureAppointment: "find-future-appointments",
        modifyReminderStatus: "modify-reminder-status",
        findscanreport: "find-bookscan-report",
        diseaseList: "fetch-diseaselist",
        fetchAllCrossConsultation: "fetch-all-cross-consultation",
        fetchAncCardDetails: "fetch-ancCard-detail",
        fetchAllLabResults: "fetch-all-test-results",
        fetchAllMeasuresFlowSheet: "fetch-all-measure-flowsheet",
        ObFullReport: "Ob-Full-Report",
        fetchBirthHistory: "fetch-birth-history",
        fetchallancvisit: "fetch-all-ancvisit",
        fullreport: "full-report",
        intialDetails: "intial-details",
        labDetails: "lab-details",
        addFollowup: "add-followup",
        labReportsForPatient: "ob-lab-reports-patient",
        fetchPatientAllAncCard: "fetch-all-anc-card",
        findVisitNumber: "find-visit-number",
        findappointmentdetails: "find-appointment-detail",
        obreports: "ob-reports",
        addTelephonetraige: "add-telephonetraige",
        fetchtelephonetraige:"fetch-telephonetraige"
    };

    static readonly dentalEncounter = {
        base: "dental-encounter",
        findDashboard: "find-dashboard",
        modify: "modify",
        find: "find",
        skin: "save-clinical-findings",
        fullTranscript: "find-full-transcript",
        findPrescription: "find-prescription",
    };

    static readonly homeopathy = {
        base: "homeopathy",
        find: "find",
        findDashboard: "find-dashboard",
        findFullTranscript: "find-full-transcript",
        modify: "modify",
    };

    static readonly ticket = {
        base: "tickets",
        checkUser: "check-user",
        getCategory: "get-categories",
        create: "generate-ticket",
        fetch: "fetch-all",
        getCount: "get-count",
        getTicketDetails: "get-ticket-details",
        modify: "modify-ticket",
        getTimeline: "get-ticket-timeline",
        assign: "assign-ticket",
        addOwner: "add-ticket-owner",
        updateComments: "update-ticket-comments",
        getPerformance: "get-performance",
        updateOwner: "update-ticket-owner",
        modifyTicket: "modify-ticket"
    };

    static readonly validators = {
        base: "validate",
        email: "email",
        mobile: "mobile",
        npi: "npi",
        userName: "username",
        pharmacyProduct: "pharmacy-product"
    };

    static readonly users = {
        base: "users",
        add: "add",
        fetch: "fetch",
        update: "update",
        modifyStatus: "modify-status",
        find: "find",
        lockStatus: "lock-status"
    };

    static readonly generalAdvice = {
        base: "general-advice",
        add: "add",
        fetch: "fetch",
        update: "update",
        find: "find",
        delete: "delete"
    };

    static readonly ward = {
        base: "wards",
        add: "add",
        fetch: "fetch",
        update: "update",
        delete: "delete",
        fetchWard: "ward-Fetch",
        changestatus: "change-status"
    };

    static readonly room = {
        base: "rooms",
        add: "add",
        fetch: "fetch",
        fetchRoom: "fetch-room",
        update: "update",
        delete: "delete",
        modifyOTRoom: "modify-ot-room",
        fetchOTRoom: "fetch-ot-room",
        modifyStatus: "modify-status",
        fetchOTRoomSurgery: "fetch-surgery",
        modifyRoomStatus: "change-room-status",
        fetchRoomWithChargesNew: "fetch-room-with-charges-new",
        fetchTemplateRoomCharges: "fetch-template-room-charges"
    };

    static readonly bed = {
        base: "beds",
        add: "add",
        fetch: "fetch",
        update: "update",
        delete: "delete",
        fetchBed: "fetchBed",
        changeStatus: "change-status",
        bedAvailability: "bed-availablity",
        modifyBedStatus: "modify-bed-status",
        fetchNonTransitBeds: "fetch-non-transit-beds",
        updateBedStatus: "update-bed-status"
    };

    static readonly department = {
        base: "departments",
        add: "add",
        fetch: "fetch",
        update: "update",
        delete: "delete"
    };

    static readonly chargeGrop = {
        base: "chargeGrops",
        add: "add",
        fetch: "fetch",
        update: "update",
        chargeGroup: "chargeGroup",
        delete: "delete",
    };

    static readonly charge = {
        base: "charges",
        add: "add",
        fetch: "fetch",
        update: "update",
        delete: "delete",
        repeatType: "repeatType-fetch"
    };

    static readonly role = {
        base: "roles",
        add: "add",
        fetch: "fetch",
        delete: "delete",
        update: "update",
        modifyStatus: "modify-status",
        addRoleDepartment: "add-RoleDepartment",
        fetchRoleDepartment: "fetch-roledepartment"
    };

    static readonly discharges = {
        base: "discharges",
        add: "add",
        fetch: "fetch",
        delete: "delete",
        update: "update",
        modifyStatus: "modify-status"

    };

    static readonly payU = {
        base: "payU",
        fetchStatus: "fetchStatus",
        refund: "refund",
        fetchRefundStatus: "fetchRefundStatus",
        authenticate: "authenticate",
        getAccountDetails: "getAccountDetail",
        smartSend: "smartSend",
        payment: "payment",
        addWebHook: "addWebHook",
        deleteWebHook: "deleteWebHook",
        fetchPayoutTransfers: "fetchPayoutTransfers",
        WebHook: "WebHook"
    };

    static readonly insurances = {
        base: "insurance-companies",
        fetch: "fetch",
        add: "add",
        find: "find",
        delete: "delete",
        update: "update",
        modifyStatus: "modify-status"
    };

    static readonly providerDocuments = {
        base: "provider-documents",
        fetch: "fetch",
        upload: "upload",
        update: "update",
        delete: "delete"
    };

    static readonly specialization = {
        base: "specializations",
        fetch: "fetch",
        update: "update",
        delete: "delete",
        add: "add",
        modifyStatus: "modify-status",
        specializationJoinConsultationType: "specialization-join-consultation",
        fetchall: "fetch-all",
        fetchspecialization: "fetch-specialization",
        doctorSpecializationwithAllConsultations: "provider-specialization-all-consulation"
    };

    static readonly icdCodes = {
        base: "icd-code",
        fetch: "fetch",
        update: "update",
        delete: "delete",
        add: "add"
    };

    static readonly labOrderValues = {
        base: "lab-order-value",
        update: "update",
        add: "add",
        fetch: "fetch",
        delete: "delete"
    };

    static readonly radiologyValues = {
        base: "radiology-value",
        update: "update",
        add: "add",
        fetch: "fetch",
        delete: "delete"
    };

    static readonly labOrder = {
        base: "lab-order",
        update: "update",
        add: "add",
        fetch: "fetch",
        delete: "delete"
    };

    static readonly problemList = {
        base: "problem-list",
        add: "add",
        fetch: "fetch",
        update: "update",
        find: "find",
        delete: "delete"
    };

    static readonly webTelemedicine = {
        base: "web-telemedicine",
        endCall: "update-status",
        getAppointments: "get-provider-appointments",
        getNursesOrReceptionists: "get-nurses-receptionists",
        notification: "call-web",
        iOsFrequentNotification: "ios-frequent-notification",
        getTelemedicineTemplates: "get-all-templates",
        closeRooms: "close-rooms",
        getAppointmentDetails: "get-appointment-details",
        getAuthInfo: "get-authentication-info",
        callHistory: "get-call-history",
        sendLink: "send-link"
    };

    static readonly language = {
        base: "language",
        add: "add",
        fetch: "fetch",
        update: "update",
        find: "find",
        delete: "delete"
    };

    static readonly coupons = {
        base: "coupons",
        add: "add",
        fetch: "fetch",
        update: "update",
        delete: "delete"
    };

    static readonly icons = {
        base: "icons",
        addIcons: "add-icons",
        fetchIcons: "fetch-icons",
        delete: "delete"
    };

    static readonly inventory = {
        base: "inventory",
        addUnit: "create-unit",
        addCategory: "create-category",
        addRack: "create-rack",
        addGst: "create-gst",
        addProduct: "add-product",
        updateProduct: "update-product",
        deleteProduct: "delete-product",
        fetchProduct: "fetch-product",
        fetchLookup: "fetch-lookup-value",
        updateLookupValue: "update-lookup-value",
        deleteLookupValue: "delete-lookup-value",
        fetchAddedPurchaseBill: "fetch-added-purchase-bill",
        fetchStock: "fetch-inventory-stock",
        fetchReturnBillNumber: "fetch-return-bill-number",
        addReturnPurchaseBill: "add-return-purchase-bill",
        fetchAddedReturnBill: "fetch-added-purchase-return-bill",
        fetchDashboardReorder: "fetch-dashboard-reorder",
        fetchDashboardExpiry: "fetch-dashboard-expiry",
        fetchDashboardNextMonthExpiry: "fetch-dashboard-expiry-next-month",
        fetchDashboardCurrentMonthExpiry: "fetch-dashboard-expire-current-month",
        fetchProducts: "fetch-products",
        updateStock: "update-inventory-stock",
        fetchBills: "fetch-bills",
        fetchInventoryPurchaseBill: "fetch-inventory-purchase-bill",
        addPurchaseBill: "add-new-purchase-bill",
        fetchBatchNumber: "get-batch-numbers"
    };

    static readonly supplier = {
        base: "supplier",
        add: "add",
        fetch: "fetch",
        fetchActive: "fetchActive",
        update: "update",
        delete: "delete",
        modifySupplierProduct: "modify-supplier-product",
        fetchSupplierProduct: "fetch-supplier-product",
        addSupplierAccount: "add-supplier-account",
        addRateCard: "add-supplier-rate-list",
        fetchRateCard: "fetch-supplier-rate-list",
        modifyStatus: "modify-status",
        fetchUploadedDocument: "fetchUploadedDocument",
        fetchSupplierCreditNote: "fetch-supplier-creditNote",
        fetchSupplierCreditNoteWallet: "fetch-supplier-creditNote-wallet",
        downloadFile: "download-file-ftp",
        downloadFileGet: "download-file-ftp-get",
        updateCreditNumber: "update-supplier-credit-number",
        deleteDocument: "delete-uploaded-document",
        fetchReturedProduct: "fetch-Retured-Product" 
    };

    static readonly company = {
        base: "company",
        add: "add",
        fetch: "fetch",
        update: "update",
        delete: "delete"
    };

    static readonly indent = {
        base: "indent",
        add: "add-request",
        fetch: "fetch",
        delete: "delete",
        fetchDetail: "fetch-detail",
        pharmacyDepartmentIndent: "add-pharmacy-department-indent",
        fetchPharmacyDepartmentIndent: "fetch-pharmacy-department-indent",
        fetchPharmacyDepartmentIndentDetail: "fetch-pharmacy-department-indent-detail",
        approvePharmacyDepartmentIndent: "approve-pharmacy-department-indent",
        raiseIndentTowareHouse: "raise-indent-to-warehouse",
        fetchIndentsWareHouse: "fetch-indent-to-warehouse",
        fetchIndentToggleWareHouse: "fetch-indent-toggle-warehouse",
        fetchIndentToWareHouseIssue: "fetch-indent-to-warehouseissue",
        fetchWareHouseIndentProductId: "fetch-warehouseindent-detail-productid",
        fetchProductAvailability: "fetch-product-availability-warehouseissue",
        fetchAcknowledgementData: "fetch-warehouseindent-acknowledgement",
        acceptAcknowledgeMent: "accept-warehouseindent-acknowledgement",
        approveWareHouseIndentStatus: "approve-warehouse-indent-Status",
        approveDepartmentIndent: "approve-department-indent",
        approveDepartmentIndentWithAcknowledgement: "approve-pharmacy-department-indent-acknowledgement",
        isWarehouseIndentCancelled: "is-warehouse-indent-cancelled",
        isDepartmentIndentCancelled: "is-department-indent-cancelled",
    };

    static readonly issue = {
        base: "issue",
        fetchPendingIndents: "fetch-pending-indents",
        approve: "approve-indents",
        approveOTIndent: "approve-ot-indent",
        approvePharmacyIndent: "approve-pharmacy-indent",
        fetchAcknowledgmentData: "fetch-acknowledgment-data",
        acceptAcknowledgment: "accept-acknowledgment-data",
        approveInventoryIndent: "approve-inventory-indent",
        fetchInventoryAcknowledgment: "fetch-inventory-acknowledgment-data",
        acceptInventoryAcknowledgment: "accept-inventory-acknowledgment-data",
        moveStockViaPurchaseBill: "move-stock-to-retail-store",
        approveWareHouseIndent: "approve-warehouse-indent",
        fetchIssuedProducts: "fetch-issued-products",
        fetchStockTransitReport: "fetch-stock-transit-report",
        fetchAcknowledgementDataForWareHouseIssue: "fetch-acknowledge-data-for-warehouseissue",
        fetchIssuedProductsForWarehouseIssue: "fetch-issued-product-for-warehouseissue"
    };

    static readonly pharmacy = {
        base: "pharmacy",
        addCommonMasters: "create-common-masters",
        fetchLookupValue: "fetch-lookup-value",
        updateLookupValue: "update-lookup-value",
        deleteLookupValue: "delete-lookup-value",
        addProduct: "add-product",
        updateProduct: "update-product",
        fetchProduct: "fetch-product",
        fetchProducts: "fetch-products",
        deleteProduct: "delete-product",
        addPurchaseBill: "add-purchase-bill",
        fetchAddedBill: "fetch-added-purchase-bill",
        fetchBillBySupplierId: "fetch-added-purchase-bill-by-supplierId",
        fetchStock: "fetch-pharmacy-stock", //main stock w.r.t.warehouse
        fetchRetailStock: "fetch-pharmacy-retail-stock", //retail stock w r t . retail store
        addSaleBill: "add-sales-bill",
        fetchPharmacyPurchaseBill: "fetch-Pharmacy-Purchase-bill",
        fetchAddedSaleBill: "fetch-added-sales-bill",//
        fetchAddedSaleReturnBill: "fetch-added-sales-return-bill",//
        addSurgeryKit: "add-surgery-kit",
        fetchSurgeryHeader: "fetch-surgery-kit",
        fetchSurgeryDetail: "fetch-surgery-detail",
        deleteSurgeryKit: "delete-surgery-kit",
        fetchNextExpiryProducts: "fetch-next-expiring-products",
        fetchDashboardNextMonth: "dashboard-expiring-next-month",
        fetchDashboardThisMonth: "dashboard-expiring-this-month",
        fetchDashboardUnderReorderLevel: "dashboard-product-under-reorder",
        fetchDashboardNextMonthRetail: "dashboard-expiring-next-month-retail",
        fetchDashboardThisMonthRetail: "dashboard-expiring-this-month-retail",
        fetchDashboardUnderReorderLevelRetail: "dashboard-product-under-reorder-retail",
        addSaleReturnBill: "add-pharmacy-sale-return-bill",
        fetchSaleReturnBill: "get-sale-return-bill",
        addRetailStock: "add-retail-stock",
        updateStock: "update-stock",
        fetchSurgeryKitStock: "fetch-surgery-kit-stock",
        addSurgeryKitSale: "add-surgery-kit-sale-bill",
        updateSaleQuantity: "update-sale-quantity",
        deleteSaleBillProduct: "delete-sale-bill-product",
        cancelBill: "cancel-sale-bill",
        fetchProductWithStock: "fetch-product-with-stock",
        moveRetailToMain: "move-retail-to-main-stock",
        productWithMainStock: "get-main-stock-and-product",
        fetchPurchaseBillItem: "get-purchase-bill-item",
        returnPurchaseBill: "return-purchase-bill-item",
        pharmacyCategoryRevenue: "fetch-category-revenue",
        pharmacyProductRevenue: "fetch-product-revenue",
        pharmacyTopProductsRevenue: "fetch-top-products-revenue",
        addPayType: "add-pay-type",
        pharmacyDoctorCollection: "fetch-doctor-collection",
        pharmacyEmployeeCollection: "fetch-employee-collection",
        deletePurchaseBillProduct: "delete-purchase-bill-product",
        addRetailStore: "add-retail-store",
        fetchAllRetailStore: "fetch-all-retail-stores",  //retail store
        //deleteRetailStore: "delete-retail-store",
        giveDiscount: "give-discount",
        revokeDiscount: "revoke-discount",
        addNewSalesBill: "add-new-sales-bill",
        fetchAutoBillNumber: "fetch-auto-bill-number",
        sendEmail: "purchase-bill-email",
        pharmacyStockInBulk: "pharmacy-stock-bulk",
        creditPurchaseBill: "purchase-credit-bill",
        updatePurchasePay: "update-purchase-pay",
        correctSalesBill: "correct-sale-bills",
        getBillCount: "get-bill-count",
        getBatchNumbers: "get-batch-numbers",

        addRetailStoreIndent: "add-retail-store-indent",
        fetchRetailStoreIndents: "fetch-retail-store-indent",
        fetchRetailIndentDetail: "fetch-retail-indent-detail",
        modifyNewPurchaseBill: "modify-new-purchase-bill",
        addRetailStoreNew: "add-retail-store-new",
        deactivateRetailStore: "deactivate-retail-store",
        activateRetailStore: "activate-retail-store",
        fetchPharmacyCreditPurchaseBill: "fetch-Pharmacy-Credit-Purchase-bill",
        fetchRetailIssueDetail: "fetch-retail-issue-detail",
        fetchRetailStoreIssues: "fetch-retail-store-issue",
        fetchPharmacyRetailUsers: "fetch-pharmacy-retail-users",
        fetchAddedSaleBillForPatient: "fetch-added-sales-bill-for-patient",
        fetchProductPurchaseHistory: "fetch-product-purchase-history",
        fetchDrugs: "fetch-drugs",
        modifyMedFrequency: "modify-med-frequency",
        fetchMedFreq: "fetch-med-frequency",
        changeMedFreqStatus: "change-med-frequency-status",
        returnToVendor: "return-to-vendor",
        fetchWareHouseExpiryReturn: "fetch-Return-To-Vendor-From-wareHouseExpiry",
        fetchPharmacuWareHouseStocks: "fetch-pharmacy-warehouse-stock",
        fetchFilteredData: "filtered-data-return-to-vendor",
        getAllunits: "get-all-units",
        fetchRetailStore: "fetch-retail-store",
        fetchWareHouses: "fetch-ware-houses",
        moveRetailFromDashBoard: "move-retail-from-dashboard",
        fetchReturnFromRetailExpiry: "fetch-from-retailexpiry",
        fetchIssueDetail: "fetch-issue-detail",
        fetchRetailIssues: "fetch-retail-issues",
        fetchwareHouse: "fetch-warehouse",
        fetchPharmacyRetuenToVender: "fetch-pharmacy-return-to-vendor",
        fetchWareHouseStockForWareHouseIndent: "fetch-pharmacy-warehouse-stock-for-warehouseindent",
        changeProductStatus: "change-product-status",
        acceptRetailIndent: "accept-retail-indent",
        insertReturnToVendorGatepass: "return-to-vendor-gatepass",
        fetchReturnToVendorDocument: "fetch-return-to-vendor-document",
        fetchReturnToVendorDocumentDetails: "download-returntovendor-file-ftp-get",
        fetchWareHouseStockReport:"fetch-warehouse-stock-report",
        fetchStockForAdjust: "fetch-stock-adjust",
        fetchproductForStockAdjust: "fetch-stock-adjust-product",
        fetchRateAndQuantity: "fetch-rate-and-quantity",
        modifyAdjustmentStock: "modify-adjustment-stock",
        fetchStockAdjustmentReport: "fetch-stock-adjustment-report",
        fetchGrn: "fetch-grn",
        cancelGrn: "cancel-grn",
    };

    static readonly providerBankAccounts = {
        base: "provider-bank-account",
        add: "add",
        update: "update",
        find: "find"
    };

    static readonly patientFamilyMedicalHistory = {
        base: "patient-family-medical-history",
        fetch: "fetch",
        add: "add",
        update: "update",
        delete: "delete"
    };

    static readonly timeline = {
        base: "timeline",
        fetch: "fetch",
        view: "view",
        fetchtimelinemenu: 'fetch-timelinemenu',
        fetchtimelinedata: "fetch-timelinedata",
        insert: "insert",
        fetchinvestigationchecklist: "fetch-investigation-checklist",
        fetchDoctorDetail: "fetch-doctorDetail",
        fetchChargeDetail: "fetch-chargeDetail",
        fetchVisitDetail: "fetch-visitDtail",
        fetchallinvestigationchecklist: "fetch-all-investigation-checklist",
        fetchallinvestigationchecklistvalue: "fetch-all-investigation-checklist-values",
    };

    static readonly pharmacyRequest = {
        base: "pharmacy-request",
        addIndent: "add-indent",
        addIndentBulk: "add-indent-bulk",
        fetchHeader: "fetch-indent-header",
        fetchDetail: "fetch-indent-detail",
        allIndent: "fetch-all-indent",
        approveIndents: "approve-pending-indent",
        getIssuedRecord: "get-issue-indent-detail",
        otIndentDetail: "get-ot-indent-detail",
        deleteIndent: "delete-indent",
        rejectIndentProduct: "reject-indent-product",
        fetchIndent: "fetch-indent",
        approveOTIndent: "approve-ot-indent",
        addReturnRequest: "add-return-request",
        fetchReturn: "fetch-return",
        fetchReturnRequests: "fetch-return-request",
        acceptReturn: "accept-return",
        rejectReturn: "reject-return",
        pharmacyInpatientSaleReport: "pharmacy-inpatient-report",
        pharmacyIpReturnReport: "pharmacy-inpatient-returnedProdcucts-report",
        addServiceIndent: "add-service-indent",
        fetchPackageForPharmacy: "fetch-package-for-pharmacy",
        fetchIndentDetailToApproveIssue: "fetch-indent-for-approve",
        pharmacyInpatientSaleReceipt: "pharmacy-inpatient-receipt",
        changeStatusIsInclude: "update-status-isinclude",
        acceptIssuedIndent: "accept-issued-indent"     
    };

    static readonly pharmacyLog = {
        base: "pharmacy-log",
        fetchPharmacyLogs: "fetch-pharmacy-logs",
        insertPharmacyLogs: "insert-pharmacy-logs"
    };


    static readonly laboratory = {
        base: "laboratory",
        addUnit: "create-unit",
        modifyLab: "modify-lab",
        fetchLab: "fetch-lab",
        fetchDetail: "fetch-lab-detail",
        fetchLabLocationCharges: "fetch-lab-location-charges",
        deleteLab: "delete-lab",
        addPatientBooking: "add-lab-booking",
        fetchBookingLab: "fetch-lab-bill-detail",
        fetchBookingHeader: "fetch-lab-booking-header",////
        fetchBookingDetail: "fetch-lab-booking-detail",//
        fetchBookingHeaderWithDetail: "fetch-lab-booking-header-with-detail", //with details
        updateLabStatus: "update-lab-status", // lab status
        addBulkLabParameters: "add-bulk-lab-parameters",
        fetchLabReportMain: "lab-report-main",
        fetchLabReportParameter: "lab-report-result",
        fetchLabReport: "fetch-lab-daily-report",
        verifyLabReport: "verify-lab-report",  //lab
        modifyLabPackage: "modify-lab-package",
        fetchAllLabPackage: "fetch-all-lab-package",
        fetchLabPackageDetail: "fetch-lab-package-detail",
        deleteLabPackage: "delete-lab-package",
        fetchLabBookingDetailForPackage: "fetch-lab-booking-detail-for-package", // packa info with inside labdetail
        //  updateLabBookingPackageSampleStatus: "update-booking-package-sample-status", //pakage status
        verifyLabBookingPackage: "lab-booking-package-verify",    //pack
        fetchLabReportResultPackage: "lab-report-result-package",
        labBookingPackageReport: "lab-booking-package-report",
        cancelLabBooking: "cancel-lab-booking",
        cancelSingleLab: "cancel-single-lab-from-booking",
        sendReportSMS: "send-lab-report-sms",
        modifyDepartment: "modify-lab-department",
        fetchAllDepartment: "fetch-lab-departments",
        modifyDepartmentStatus: "modify-lab-departments-status",
        fetchCombinedLabAndPackage: "fetch-combined-lab-and-package",
        updateLabBarcode: "update-barcode-for-lab",
        // updatePackageLabBarcode: "update-barcode-for-packaged-lab",
        updateSampleReceived: "update-sample-received-status", // single
        updateSelectedSampleReceived: "update-received-status",
        updateSelectedlabStatus: "update-selected-lab", //sample collect both
        fetchExistingLabPatients: "fetch-existing-labs-patients",
        fetchSampleStatus: "fetch-sample-status",
        fetchImportLab: "fetch-lab-import",
        modifyImportLab: "modify-import-lab",
        fetchImportLabPackage: "fetch-import-lab-package",
        modifyImportPackage: "modify-import-package"
    };

    static readonly labLog = {
        base: "lab-log",
        fetchLabLogs: "fetch-lab-logs",
        insertLabLogs: "insert-lab-logs"
    };

    static readonly scanLog = {
        base: "scan-log",
        fetchScanLogs: "fetch-scan-logs",
        insertScanLogs: "insert-scan-logs"
    };

    static readonly laboratoryDashboard = {
        base: "laboratory-dashboard",
        labsDashboardRevenue: "fetch-lab-details-dashboard-revenue",
        labsDashboard: "fetch-lab-details-dashboard"
    };

    static readonly inventorylog = {
        base: "inventorylog",
        fetch: "fetch",
        insert: "insert"
    };

    static readonly inventoryImport = {
        base: "inventory-import",
        addProductSheet: "add-product-sheet",
        fetchUploadHistory: "fetch-upload-history",
        fetchUplodedProducts: "fetch-uploaded-products",
        addProductWithPurchaseBill: "add-product-sheet-with-purchase-bill",
        fetchUploadedPurchaseBills: "fetch-uploaded-purchase-bills"
    };

    static readonly pharmacyImport = {
        base: "pharmacy-import",
        addProductSheet: "add-product-sheet",
        fetchUploadHistory: "fetch-upload-history",
        fetchUplodedProducts: "fetch-uploaded-products",
        addProductWithPurchaseBill: "add-product-sheet-with-purchase-bill",
        fetchUploadedPurchaseBills: "fetch-uploaded-purchase-bills",
        uploadWareHouseSheet: "upload-warehouse-record",
        fetchUploadedWareHouse: "fetch-uploaded-warehouse",
        uploadRetailStoreRecord: "upload-retail-store-record",
        fetchUploadedRetailStores: "fetch-uploaded-retail-stores",
        uploadDepartmentRecord: "upload-department-record",
        fetchUploadedDepartments: "fetch-uploaded-departments",
        uploadRetailStoreStock: "upload-retail-store-stocks",
        fetchUploadedRetailStocks: "fetch-uploaded-retail-stocks"
    };

    static readonly dashboardWidgets = {
        base: "dashboard-widget",
        fetch: "fetch",
        insert: "insert-widget",
        execute: "execute-widget",
        update: "update-widget",
        deactivate: "deactivate-widget",
        activate: "activate-widget"
    };

    static readonly dynamicReport = {
        base: "dynamic-report",
        execute: "execute-the-query",
        insert: "insert",
        fetch: "fetch",
        deactivate: "deactivate",
        update: "update",
        fetchMenu: "fetch-report-menu",
        fetchModuleTemplates: "fetch-module-templates",
        fetchReport: "fetch-report",
        addImage: "insert-image",
        fetchImagePath: "fetch-image-path"
    };

    static readonly dynamicReportDashboard = {
        base: "dynamic-report-dashboard",
        insert: "insert",
        update: "update",
        fetch: "fetch",
        fetchFunctionData: "run-function",
    };

    static readonly dynamicDashboard = {
        base: "dynamic-dashboard",
        fetch: "fetch", //// acording to role wise fetching  from SuperAdmin //
        insert: "insert",
        update: "update",
        delete: "delete"
    };

    static readonly visitType = {
        base: "visitors",
        add: "add",
        fetch: "fetch",
        update: "update",
        delete: "delete",
        modifyStatus: "modify-status",
    };
    static readonly documentType = {
        base: "document-type",
        add: "add",
        fetch: "fetch",
        update: "update",
        delete: "delete",
        modifyStatus: "modify-status",
    };

    static readonly chargeTypes = {
        base: "charge-types",
        add: "add",
        fetch: "fetch",
        update: "update",
        delete: "delete",
        modifyStatus: "modify-status",
    };

    static readonly patientQueue = {
        base: "patient-queue",
        generateTicket: "generate-ticket",
        fetchAllQueue: "fetch-all-queue",
        changePatientQueueStatus: "change-queue-status"
    };
    static readonly floor = {
        base: "floors",
        add: "add",
        fetch: "fetch",
        update: "update",
        delete: "delete",
        floorFetch: "floor-fetch", // as resourses 4 all location
        changeStatus: "change-status"
    };


    static readonly setting = {
        base: "setting",
        fetch: "fetch",
        imageUpload: "image-upload",//upload
        fetchImage: "fetch-image",  //all fetch
        applyLogo: "apply-logo",//
        fetchLogo: "fetch-logo",//required removed
        //  printSetting: "print-setting",//update
        //   fetchPrintSetting: "fetch-print-setting",// fetch
        fetchFontType: "fetch-fontType",
        applyFont: "apply-font",
        addStoreDetail: "add-store-detail",
        fetchStoreSetting: "fetch-store-settings",
        applyStoreSetting: "apply-store-settings",
        getAppliedPharmacySettings: "get-applied-store-settings",
        //fetchMessageSettings: "fetch-message-settings",
        modifyMessageSetting: "modify-message-settings",
        appointmentSetting: "appointment-setting",
        insert: "insert",
        update: "update",
        fetchNewBanner:"fetch-new-banner"
    };

    static readonly insuranceAdmission = {
        base: "insurance-approval",
        //modifyInsurance: "modify-insurance-admission",
        addInsuranceApprovals: "add-insurance-approval",
        getAdmission: "get-admission-insurance",
        fetchAll: "fetch-all",
        addTimeline: "add-insurance-timeline",
        fetchAllTimeline: "fetch-all-insurance-timeline",
        checkInsuranceApproval: "check-insurance-approval",
        fetchInsuranceApprovals: "fetch-all-insurance-approvals",
        getInsuranceApprovals: "get-insurance-approvals"
    };

    static readonly nurseModule = {
        base: "nurse-module",
        fetch: "fetch",
        fetchBedWise: "fetch-bed-wise",
        fetchShiftsBasics: "fetch-shifts-basics",
        fetchAvailableNurse: "fetch-available-nurse",
        fetchShift: "fetch-shifts",
        addShift: "add-shift",
        updateShift: "update-shift",
        fetchShiftSlot: "fetch-shift-slots",
        addShiftSlot: "add-shift-slot",
        modifyStatus: "modify-status",
        fetchPendingInitialAdmission: "fetch-pending-initial-assessment",
        fetchNurseDashboardDetails: "fetch-nurse-dashboard-details"
    };

    static readonly rota = {
        base: "rota",
        fetch: "fetch",
        fetchEvent: "fetch-event",
        insert: "insert"
    };

    static readonly nurseShiftMap = {
        base: "nurse-shift-map",
        add: "add",
        fetch: "fetch"
    };


    static readonly vitalType = {
        base: "vital-type",
        add: "add",
        fetch: "fetch",
        update: "update",
        delete: "delete",
        fetchUnitType: "fetch-unit-type"
    };

    static readonly unitType = {
        base: "unit-type",
        add: "add",
        fetch: "fetch",
        update: "update",
        delete: "delete",
    };

    static readonly discountManagement = {
        base: "discount-management",
        modifyModule: "modify-discount-module",
        fetchModules: "fetch-discount-module",
        modifyDiscountsPerModule: "modify-discount-per-module",
        moduleDetail: "module-detail",
        getRoleBasedDiscount: "get-role-based-discount",
        modifyStatus: "discount-Management-modify-status"
    };

    static readonly assessmentType = {
        base: "assessment-type",
        add: "add",
        fetch: "fetch",
        update: "update",
        delete: "delete"
    };

    static readonly pharmacyTracking = {
        base: "pharmacy-tracking",
        fetchProducts: "fetch-purchased-products",
        fetchPurchase: "fetch-purchase-details",
        fetchPurchasedReturn: "fetch-Purchased-return",
        FetchWareHouseRecord: "fetch-wareHouse-record",
        fetchStockToRetail: "fetch-stock-to-retail",
        FetchRetailToMainMoved: "fetch-retail-moved",
        FetchRetailRecord: "fetch-retail-record",
        fetchRetailStoreSale: "fetch-retailstore-sale",
        fetchSaleReturn: "fetch-sale-return",
        fetchRetailStoreIssuedSale: "fetch-retailstore-issue-to-ip"
    };

    static readonly nurseShift = {
        base: "nurse-shift",
        fetchBeds: "fetch-beds",
        fetchBasicBeds: "fetch-beds-basic",
        getNurse: "get-nurse",
        fetch: "fetch",
        insert: "insert",
        move: "move",
        fetchMoves: "fetch-moves",
        update: "update",
        copy: "copy",
        insertShiftAsync: "insert-from-nursebedview"
    };

    static readonly operationTheater = {
        base: "ot",
        raiseIndent: "raise-indent",
        fetchIndentHeader: "fetch-indent-header",
        fetchIndentDetail: "fetch-indent-detail",
        pendingIndentCount: "pending-indent-count",
        fetchOTStock: "fetch-operation-stock",
        modifySurgery: "modify-surgery",
        fetchAllSurgery: "fetch-all-surgery",
        modifyStatus: "modify-status",
        fetchOtWithCharges: "fetch-ot-with-charges",
        fetchOtWithChargesNew: "fetch-ot-with-charges-new"
    };

    static readonly surgeryLink =
        {
            base: "surgery-link",
            addService: "add-charge",
            fetchServices: "fetch-linked-services",
            delete: "delete"
        };
    static readonly surgeryLinkLab = {
        base: "surgery-link-lab",
        addLab: "add-lab",
        fetchLabs: "fetch-linked-lab",
        delete: "delete"
    };

    static readonly surgeryLinkScan = {
        base: "surgery-link-scan",
        addScan: "add-scan",
        fetchScan: "fetch-linked-scan",
        delete: "delete"
    };


    static readonly surgeryLinkPharmacy = {
        base: "surgery-link-pharmacy",
        addPharmacy: "add-pharmacy",
        fetchPharmacy: "fetch-linked-pharmacy",
        delete: "delete"
    };
    static readonly demandBook = {
        base: "demand-book",
        insert: "insert",
        fetch: "fetch",
        update: "update"
    };

    static readonly modulesMaster = {
        base: "module-master",
        fetch: "fetch",
        add: "add",
        update: "update",
        deleteModules: "delete-modules"
    };

    static readonly pharmacyStore = { //warehouse
        base: "pharmacy-ware-house",
        modifyWareHouse: "modify-ware-house",
        fetchWareHouses: "fetch-ware-house",
        fetchWareHousesWithRetailCount: "fetch-ware-house-with-retail-count",
        modifyWareHouseStatus: "modify-warehouse-status",
        getOnlyWareHouses: "get-only-warehouses",
        getAllWareHouses: "get-all-warehouses",
        modifyProductType: "modify-pharmacy-product-type",
        fetchProductType: "fetch-pharmacy-product-type",
        fetchProductSubType: "fetch-pharmacy-product-sub-type",
        modifyProductSubType: "modify-pharmacy-product-sub-type",

    };

    static readonly mealTypes = {
        base: "meal-types",
        fetch: "fetch",
        insert: "insert",
        update: "update",
        modifyStatus: "modify-status",
        fetchActive: "fetch-active"
    };

    static readonly gynEncounter = {
        base: "gyn-encounter",
        find: "find",
        findPrescription: "find-prescription",
        findFullTranscript: "find-full-transcript",
        findDashboard: "find-dashboard",
        findData: "find-encounter",
        modify: "modify",
        skin: "gyn-save-skin",
        findGynEncounter: "find-gyn-encounter",
        vitals: "fetch-vitals",
        addGynCard: "add-gynCardGeneration",
        fetchGynCard: "fetch-gynCard",
        fetchGyn: "find-gyn",
        findVisitNo: "find-visitNo",
        InsertCommonEncounter: "add-common-encounter",
        fetchCommonEncounter: "fetch-common-encounter",
        nurseNotesReport: "nurse-note-report",
        encounterComplete: "encouter-completed",
        fetchGynPartnerDetails: "fetch-gynePartner-detail",
        gynereport: "gyne-report",
        findvisitNumber: "find-visit-number",
        fetchallgyncvisit:"find-all-gyn-visit",
        fetchalltelephonetraige:"find-all-telephonetriage",
    };

    static readonly employeeShifts = {
        base: "employee-shifts",
        find: "find",
        add: "add",
        update: "update",
        delete: "delete",
        fetch: "fetch"
    };

    static readonly providerSchedule = {
        base: "provider-schedule",
        fetch: "fetch",
        fetchEvent: "fetch-events",
        fetchAvailability: "fetch-availability"
    };

    static readonly hwc = {
        base: "hwc-patient",
        insert: "insert",
        update: "update",
        fetch: "fetch-all",
        delete: "delete"
    };


    static readonly scanTest = {
        base: "scan-test",
        fetch: "fetch-test",
        addTest: "add-test",
        updateTest: "update-test",
        modifyScanTestStatus: "modify-test-status",
        fetchTest: "fetch",
        updateLookupValue: "update-category",
        addScanCategory: "add-category",
        deleteScanCategory: "delete-scan-category",
        fetchDetails: "fetch-details",
        fetchAll: "fetch-all",
        fetchImportTest: "fetch-import-test",
        modifyImportTest: "modify-import-test",
        fetchAllScanTest: "fetch-all-scan-test",
        fetchTestCode: "fetch-test-codes",
        fetchScanWithCharges: "fetch-scan-with-charges",
        fetchScanWithChargesNew: "fetch-scan-with-charges-new",
        fetchScanTests: "fetch-test",
        fetchTemplateScanCharges: "fetch-template-scan-charges",
        updatescanservicestatus: "update-scan-service-status",
    }
    static readonly scanMachine = {
        base: "scan-machine",
        fetch: "fetch-scan-machine",
        modifyMachine: "modify-scan-machine",
        modifyScanTestStatus: "modify-scan-machine-status",
        fetchScanMachineTest: "fetch-scan-machine-test",
        fetchImportMachine: "fetch-import-machine",
        modifyImportMachine: "modify-import-machine"
    };

    static readonly chargeCategory = {
        base: "charge-category",
        fetch: "fetch",
        add: "add",
        update: "update",
        modifyStatus: "modify-status",
        fetchLocationCharges: "fetch-location-charges",
        fetchRoomCharges: "fetch-room-charges",
        insertCategoryCharges: "insert-category-charges",
        updateCategoryCharges: "update-category-charges",
        setDefaultCharge: "set-default-category-charges",
        modifyRooms: "modify-roomstatus"
    };

    static readonly idProof = {
        base: "id-proof",
        fetch: "fetch-all",
        insert: "add",
        update: "update",
        delete: "delete",
        modifyStatus: "modify-status",
        fetchActive: "fetch-active"
    };

    static readonly payTypes = {
        base: "paytype",
        fetch: "fetch",
        insert: "insert",
        update: "update",
        modifyPayTypeStatus: "modify"
    };

    static readonly scanMachineAvailability = {
        base: "scan-machine-availability",
        fetchAll: "fetch-all",
        modifyMachineAvailability: "modify-machine-availability",
        update: "update",
        delete: "delete",
        modifyStatus: "modify-status",
        fetchActive: "fetch-active",
        fetch: "fetch",
        fetchSelectedMachineAvailability: "fetch-machine-availability",//
        fetchMachineAvailabilityBasedonDays: "fetch-machine-availability-for-multipleDays",
        cancelScanMachineAvailability: "cancel-scan-machine-availability"

    };

    static readonly referenceTypes = {
        base: "reference-type",
        fetch: "fetch",
        add: "add",
        update: "update",
        delete: "delete"
    };

    static readonly patientEncounter = {
        base: "patient-encounter",
        add: "modify-medication",
        fetch: "fetch-medication",
        deleteSingleMedication: "delete-single-medication",
        modifyEncounterLab: "modify-encounter-lab",
        fetchLabs: "fetch-encounter-lab",
        fetchUnpaidLabs: "fetch-all-lab",
        deleteSingleLab: "delete-single-lab",
        verifyNonPackageLab: "verify-lab-non-package",
        verifyPackageReport: "verify-package-report",
        frequentlyUsedLab: "frequently-used-lab",
        frequentlyUsedMedication: "frequently-used-medication",
        infusionadd: "insert-pharamacy-infusion",
        infusiondelete: "delete-infusion",
        fetchInfusion: "fetch-Infusion",
        onStopMedication: "stop-medication",
        fetchCurrentMedication: "fetch-current-medication",
        addOrderPrescription: "add-orderPrescription",
        deleteMedication: "delete-medication",
        fetchMedicationForNotification: "fetch-medication-notification",
        removeVaccine:"delete-vaccine"
    };

    static readonly webNotification = {
        base: "web-notification",
        fetch: "fetch",
        obFetch: "ob-fetch"
    };

    static readonly bookScanAppointment = {
        base: "book-scan-appointment",
        add: "add",
        fetchEvent: "fetch",
        fetchFilterEvent: "fetch-filter-patients",
        reschedule: "reschedule",
        bulkReschedule: "bulk-reschedule",
        cancel: "cancel",
        bulkCancel: "bulk-cancel",
        fetchScanReport: "fetch-scan-report",
        updatePatientDetailsPaymentAsync: "update",
        fetchOutPatient: "fetch-outpatient",
        fetchScanScroll: "fetch-scan-scroll",
        updateBookScanApt: "update-book-scan-appointment",
        fetchPndtReport: "fetch-pndt-report",
        uploadScanDocument: "upload-scan-document",
        fetchScanDocument: "fetch-scan-document",
        fetchPacsImage: "fetch-pacs-document",
        fetchPatientFutureApts: "fetch-patient-future-appointments",
        inactiveScanDocument: "inactive-scan-document",
        fetchSelectedAppointment: "fetch-selected-appointment",
        fetchScanDoneReport: "fetch-scandone-report",
        fetchEncounterData: "fetch-encounter-data",
        fetchPregnancyReport:"fetch-pregnancy-report"
    };

    static readonly scanClassification = {
        base: "scan-classification",
        add: "add",
        update: "update",
        fetch: "fetch",
        delete: "delete",
        modifyClassification: "modify-scan-classification-status"
    };

    static readonly scanSubClassification = {
        base: "scan-sub-classification",
        add: "add",
        update: "update",
        fetch: "fetch",
        delete: "delete",
        fetchDetail: "fetch-details",
        modifySubClassification: "modify-scan-sub-classification-status"
    };

    static readonly pharmacyDepartment = {
        base: "pharmacy-department",
        modify: "modify",
        fetch: "fetch-pharmacy-department",
        fetchStock: "fetch-department-stock",
        modifyProductDetail: "modify-product-detail",
        getRacks: "get-pharmacy-rack",
        addConsumption: "add-departmental-consumption",
        fetchDepartmentalConsumption: "fetch-departmental-consumption",
        fetchDepartmentalConsumptionHistory: "fetch-departmental-consumption-history",
        modifyDepartmentStatus: "modify-department-status"
    };

    static readonly inventoryStore = {
        base: "inventory-store",
        modifyWareHouse: "modify-ware-house",
        fetchAllWareHouse: "fetch-all-warehouse",
        modifyStatus: "modify-warehouse-status",
        modifyDepartment: "modify-department",
        fetchInventoryDepartment: "fetch-inventory-department",
        fetchWareHouseBasedOnUser: "fetch-warehouses-based-on-user",
        fetchDepartmentBasedOnUser: "fetch-inventory-department-based-on-user",
        fetchDepartmentStock: "fetch-department-stock",
        getRacks: "get-inventory-rack",
        modifyProductDetail: "modify-product-detail",
        addDepartmentConsumption: "add-departmental-consumption",
        fetchDepartmentConsumptionHistory: "fetch-departmental-consumption-history"
    };

    static readonly providerMedication = {
        base: "provider-medication",
        addMedicationInBulk: "add-provider-medication-bulk",
        fetchAllMedication: "fetch-all-provider-medication",
        getTags: "get-all-tag-name",
        delete: "delete"
    }

    static readonly orders = {
        base: "order-prescription",
        fetchOrders: "order-Fetch",
        add: "add",
        update: "update",
        fetch: "fetch",
        delete: "delete",
        fetchTypes: "fetch-types",
        addOrderMaster: "add-order-master",
        updateOrderMaster: "update-order-master",
        fetchOrderMaster: "fetch-order-master",
        deleteOrderMaster: "delete-order-master",
        modifyencounterOrderTemplates: "modify-encounterOrderTemplates",
        fetchencounterOrderTemplates: "fetch-encounterOrderTemplates",
        changestatusencounterOrderTemplates: "change-status-encounterOrderTemplates"
    }

    static readonly labs = {
        base: "labs",
        addUnit: "create-unit",
        getMethod: "fetch-all-parameter",
        modifyDepartment: "modify-lab-department",
        fetchAllDepartment: "fetch-lab-departments",
        modifyDepartmentStatus: "modify-lab-departments-status",
        modifyParameter: "modify-lab-parameter",
        fetchParameterHeader: "fetch-lab-parameter-header",
        fetchParameterDetail: "fetch-lab-parameter-detail",
        modifyComponent: "modify-lab-component",
        fetchLabComponentHeader: "fetch-lab-component-header",
        fetchComponentDetail: "fetch-lab-component-detail",
        modifyTemplate: "modify-lab-template",
        fetchLabTemplateHeader: "fetch-lab-template-header",
        fetchLabTemplateDetail: "fetch-lab-template-detail",
        sampleType: "sample-types",
        modifyMainLab: "modify-main-lab-detail",
        fetchLabMainDetail: "fetch-main-lab-detail",
        fetchLabTaggedTemplates: "fetch-lab-tagged-templates",
        fetchLabWithCharges: "fetch-lab-with-charges",
        fetchLabCharges: "fetch-lab-charges",
        modifyParameterStatus: "modify-parameter-status",
        modifyComponentStatus: "modify-component-status",
        modifyTemplateStatus: "modify-template-status",
        modifyLabMainDetailStatus: "modify-LabMainDetail-Status",
        fetchLabsBookings: "fetch-Lab-bookings", //
        labCancelling: "lab-Cancelling",
        allLabCancelling: "lab-All-Cancelling",
        labCancel: "lab-cancel",
        labCancelRefund: "lab-cancel-Refund",
        fetchCancelledBill: "fetch-cancelled-lab-bill",
        fetchselectedLabData: "fetch-selected-lab-data",
        fetchLabStatus: "fetch-lab-status",
        updateStatusColour: "update-status-colour",
        fetchAllChargecategories: "fetch-all-charge-categories",
        fetchAvailableImportLabs: "fetch-available-import-labs",
        importLab: "import-lab",
        fetchResultAddedLabs: "fetch-result-added-labs",
        assignToDoctor: "lab-doctor-assign",
        doctorVerifyReport: "doctor-verify-report",
        fetchDoctorSignature: "fetch-doctor-signatures",
        fetchDoctorsAssigned: "fetch-doctors-assigned",
        fetchMachines: "fetch-machines",
        removeDoctor: "remove-doctor",
        fetchPatientLabs: "fetch-patient-labs",
        sampleCollectionTrackReport: "sample-collection-track-report",
        newLabReport: "fetch-lab-reports", //new lab
        fetchallobservedvalues: "get-parameter-allobservedvalues",
        fetchPatientLabDataWebReports: "fetch-selected-lab-data-webeports",
        changeSampleStatus: "change-sampleprocess-status",
        fetchParameterAddedLabsOnly: "fetch-parameter-added-labsonly",
        newDoctorAssigning: "new-doctor-assigning",
        fetchLabStatusReport: "fetch-Lab-status-reports",
        fetchPatientPastLabReports: "fetch-patient-past-lab-reports",
        fetchTransferedLabs: "fetch-transfered-labs-number",
        changeHoldStatus: "change-test-hold-status",
        fetchLabTatReport: "fetch-lab-tat-reports",
        modifyLabVacutainer: "modify-lab-vacutainer",
        fetchAllVacutainers: "fetch-lab-vacutainers",
        modifyLabVacutainerStatus: "modify-lab-vacutainer-status",
        multipleReportsVerification: "multi-labreport-verification",
        fetchExternalLabAgency: "fetch-external-lab-agency",
        fetchExternalLabAgencyDetail: "fetch-external-lab-agency-detail",
        modifyExternalLabAgencyStatus: "modify-external-lab-agency-status",
        modifyExternalLabAgencyDetailStatus: "modify-external-lab-agency-detail-status",
        modifyExternalLabAgency: "modify-external-lab-agency",
        modifyExternalLabAgencyDetail: "modify-external-lab-agency-detail",
        fetchlabsChargeofChargeCategory: "fetch-lab-chargecategory-charge",
        fetchSampleCollectedTests: "fetch-sample-collected-tests",
        unCollectSamples: "uncollect-samples",
        fetchAllSampleTypes: "fetchAllSampleTypes",
        fetchAllDepartmentTypes: "fetchAllDepartmentTypes",
        getAllSampleunCollectReasons: "get-all-sample-uncollect-reasons",
        getAllSampleRejectReasons: "get-all-sample-reject-reasons",
        checkExternalAgencyExists: "check-external-agency-exists",
        //fetchExternalLabs: "fetch-external-labs",
        fetchDoctorVerifiedReports: "fetch-doctor-verified-reports",
        fetchDoctorAssignedReports: "fetch-doctor-assigned-reports",
        fetchLabWithChargesNew: "fetch-lab-with-charges-new",
        fetchexternallabtestdetails: "fetch-externallab-test-details",
        fetchSelectedInvoiceLabData: "fetch-selected-Invoice-lab-data",
        fetchInvestigationreport: "fetch-Investigation-report-data",
        fetchTemplateLabCharges: "fetch-template-lab-charges",

        checkPackageItems: "check-package-exists-items",
        fetchLabDetails: "fetch-lab-detail",
        fetchpatientverifiedlabs: "fetch-patient-verifiedlabs",
        fetchlabparameters: "fetch-lab-parameters",
        fetchpatientalllabparameters: "fetch-patient-all-lab-parameters",
        fetchCriticalPatient: "fetch-critical-patient-reports",
        labReportEmail: "lab-report-email",
        changebulksampleprocessstatus: "change-bulk-sampleprocess-status",
        find: "find",
        pdf2imgFetch: "pdf2img"
    }

    static readonly appointmentTypes = {
        base: "appointment-types",
        insert: "insert",
        update: "update",
        fetch: "fetch-all",
        delete: "delete"

    };

    static readonly pharmacyInfusion = {
        base: "pharmacyinfusion",
        insert: "insert",
        update: "update",
        fetch: "fetch",
        delete: "delete",
        findInfusion: "fetch-Infusion-report",
        insertInfusionFromTimeLine: "insert-infusion-from-timeline"

    };

    static readonly machine = {
        base: "machine",
        fetch: "fetch-all",
        insert: "add",
        update: "update",
        delete: "delete",
        modifyStatus: "modify-status",
        fetchActive: "fetch-active"
    };

    static readonly dynamicTemplate = {
        base: "dynamic-template",
        add: "insert",
        update: "update",
        fetch: "fetch",
        delete: "delete"
    }

    static readonly labTransaction = {
        base: "lab-transaction",
        addBill: "add-booking",
        fetchAddedBill: "fetch-added-lab-bill",
        fetchForSampleCollection: "fetch-lab-for-sample-collection",
        collectSample: "collect-sample",
        transferSamples: "transfer-samples",
        transferSamplesExternal: "transfer-samples-external",
        fetchTransferedSample: "fetch-transfered-samples",
        fetchExternalTransferedSample: "fetch-transfered-samples-external",
        fetchToReceiveSample: "fetch-samples-to-receive",
        onReceiveSample: "on-sample-receive",
        fetchReceivedLabs: "fetch-received-labs",
        labParameterForInput: "lab-parameter-for-input",
        addLabParameterInput: "add-lab-parameter-input",
        addTemplateInput: "add-lab-template-input",
        fetchLabTimeline: "fetch-lab-status-timeline",
        collectSampleNew: "collect-sample-new",
        fetchTransferedTrackRecord: "fetch-transfered-track-record",
        fetchDemographData: "fetch-demograph-data",
        uploadLabData: "upload-lab-report",
        fetchLabBookingDetail: "fetch-lab-booking-detail",
        fetchForSampleTransfer: "fetch-lab-for-sample-transfer",
        fetchNewLabBookingDetail: "fetch-new-lab-booking-detail",
        addTechnicianVerification: "add-technician-verification",
        fetchForTechnicianAndDoctor: "fetch-for-technician-and-doctor",
        fetchPreviousTransferDetail: "fetch-previous-transfer-detail",
        uploadConsentForm: "upload-lab-consent-form",
        fetchBookedLabswithoutCancelled: "fetch-labs-without-cancelled",
        onReceiveSampleNew: "on-sample-receive-new",
        onDepartmentAccept: "department-accept-sample",
        fetchForExtenalLabAgency: "fetch-external-lab-for-agencyDetail",
        fetchTestMessages: "fetch-test-messages",
        genders: "genders",
        addTestMessage: "add-test-message",
        inactiveLabDocument: "inactive-lab-document",
        fetchNewLabBookingDetailForDocument: "fetch-new-lab-booking-document-details",
        fetchLabParameters: "fetch-Lab-Parameter-values",
        addsampletype: "add-lab-sample-type",
        fetchlabsampletype: "fetch-lab-sample-type",
        updatelabsampletype: "update-lab-sample-type",
        addlabparametermethod: "add-lab-parameter-method",
        fetchlabparametermethod: "fetch-lab-parameter-method",
        updatelabparametermethod: "update-lab-parameter-method",
        fetchnabl: "fetch-nabl",
        nablstatuschange: "change-status",
        fetchLabAntiBiotic: "fetch-lab-antibiotic",
        fetchLabAntiBioticResultTypes: "fetch-lab-antibiotic-resulttypes",
        changlabantibiotic: "change-lab-antibiotic-changes",
        addlabantibiotic: "modify-lab-antibiotic",
        modifyAntiBioticMaster: "modify-antibiotic-master",
        fetchAntiBioticMaster: "fetch-antibiotic-master",
        removeparametervalues: "remove-parameter-values",
        SendLabBookingEmail: "send-lab-booking-email",
        setTemplateforTest: "set-template-for-test",
        fetchLabAllInfromation: "fetch-lab-information",
        labAgencyBilling: "lab-agency-billing",
        fetchAgencyBill: "fetch-agency-bill",
        modifyAgencyBilling: "update-agency-billing",
        nablform: "nabl-form",
        bookingdoctorverifydetail: "booking-doctor-verify-detail",
        sampledepartmentacceptbulkupdate: "sample-department-accept-bulk-update",
        getlocationlogos: "get-location-logos"
    }

    static readonly payCategories = {
        base: "payCategories",
        fetch: "fetch",
        insert: "insert",
        update: "update",
        modifyPayTypeStatus: "modify"
    };

    static readonly salutation = {
        base: "salutation",
        insert: "add",
        update: "update",
        modifyStatus: "modify-status",
        fetch: "fetch-all",
        fetchActive: "fetch-active"
    };
    static readonly nationality = {
        base: "nationality",
        insert: "add",
        update: "update",
        modifyStatus: "modify-status",
        fetch: "fetch-all",
        fetchActive: "fetch-active"
    };
    static readonly religion = {
        base: "religion",
        insert: "add",
        update: "update",
        modifyStatus: "modify-status",
        fetch: "fetch-all",
        fetchActive: "fetch-active"
    };
    static readonly gender = {
        base: "gender",
        insert: "add",
        update: "update",
        delete: "delete",
        modifyStatus: "modify-status",
        fetch: "fetch",
    };
    static readonly maritalStatus = {
        base: "maritalStatus",
        insert: "add",
        update: "update",
        modifyStatus: "modify-status",
        fetch: "fetch-all",
        fetchActive: "fetch-active"
    };
    static readonly componentType = {
        base: "component-type",
        insert: "insert",
        update: "update",
        fetch: "fetch-all"
    }

    static readonly component = {
        base: "component",
        insert: "insert",
        update: "update",
        fetch: "fetch-all"
    }

    static readonly referralDoctor = {
        base: "referralDoctor",
        add: "add",
        fetch: "fetch",
        update: "update",
        modifyStatus: "modify-status",
        delete: "delete"
    };

    static readonly providerScheduleCharges = {
        base: "provider-schedule-charges",
        add: "add",
        fetch: "fetch",
        update: "update",
        delete: "delete",
        doctorCharge: "doctor-charge",
        dayTypes: "fetch-day-part",
        modifyStatus: "modify-status",
        fetchProviderSpecialization: "fetch-provider-specialization"
    };

    static readonly consultationType = {
        base: "consultation-type",
        add: "add",
        fetch: "fetch",
        update: "update",
        delete: "delete",
    };
    static readonly genders = {
        base: "genders",
        insert: "add",
        update: "update",
        delete: "delete",
        modifyStatus: "modify-status",
        fetch: "fetch",
    };
    static readonly providerAvailability = {
        base: "provider-availability",
        fetchSlots: "fetch-slots",
        fetchSlotsOnly: "fetch-slots-only",
        fetch: "fetch",
        fetchAvailabilities: "fetch-availabilities",
        modify: "modify",
        delete: "delete",
        modifyStatus: "modify-status",
        fetchVisitTypes: "fetch-visit",
        fetchVisitTypeIp: "fetch-visit-ip",
        fetchChargeType: "fetch-charge-type",
        modifyOperationAvailability: "modify-operation-availability",
        fetchOperationAvailability: "fetch-operation-availability",
        addProviderLeave: "add-provider-leave",
        updateProviderLeave: "update-provider-leave",
        cancelProviderLeave: "cancel-provider-leave",
        cancelProviderAvailability: "cancel-provider-availability",
        cancelProviderBreak: "cancel-provider-break",
    };

    static readonly template = {
        base: "template",
        insert: "insert",
        fetchModuleTemplates: "fetch-module-templates"
    }


    static readonly appointmentCheckPoints = {
        base: "check-points",
        fetch: "fetch"
    }

    static readonly providerScheduleVisits = {
        base: "provider-schedule-visits",
        add: "add",
        update: "update",
        fetch: "fetch",
        doctorVisit: "doctor-visit",
        modifyStatus: "modify-status",
        fetchProviderSpecialization: "fetch-provider-specialization"
    }

    static readonly doctorScheduleVisits = {
        base: "doctor-schedule-visits",
        add: "add",
        update: "update",
        fetch: "fetch",
        doctorVisit: "doctor-visit",
        modifyStatus: "modify-status",
        fetchProviderSpecialization: "fetch-provider-specialization",
        isFutureAppointmentExists: "future-appointments-exists"
    }

    static readonly cubicles = {
        base: "cubicle",
        fetch: "fetch",
        assign: "assign",
        unassign: "unassign",
        insert: "insert-cubicle",
        update: "update-cubicle",
        updateStatus: "update-cubicle-status",
        delete: "delete",
        assignConsultantDoctor: "assign-consultant-doctor",
        unassignConsultantDoctor: "unassign-consultant-doctor",
    };

    static readonly ivfEncounter = {
        base: "ivf-encounter",
        add: "add",
        findDashboard: "find-dashboard",
        find: "find",
        modify: "add",
        fullTrascript: "find-full-transcript",
        encounterComplete: "encouter-completed"
    };
    static readonly pediatricEncounter = {
        base: "pediatric-encounter",
        find: "find",
        findDashboard: "find-dashboard",
        findPrescription: "find-prescription",
        modify: "modify",
        findFullTranscript: "find-full-transcript",
        calculateEDD: "calculate-edd",
        calculateGA: "calculate-ga",
        findVisitNo: "find-visitNo",
        fetchPatientMeasurementHistory: "fetch-patient-measurement-history",
        findData: "find-encounter",
        encounterCompleted: "encouter-completed",
        findFutureAppointment: "find-future-appointments",
        addDenverChart: "add-denver-chart",
        findCountNo: "find-count",
        fetchVaccine: "fetch-vaccines",
        findApp: "find-app"
    };

    static readonly neonatalEncounter = {
        base: "neonatal-encounter",
        find: "find",
        findDashboard: "find-dashboard",
        findPrescription: "find-prescription",
        modify: "modify",
        findFullTranscript: "find-full-transcript",
        findPatient: "find-patient",
        findData: "find-encounter",
        findCommonData: "find-details",
        findLabDetails: "find-lab-details",
        findmotherDetail: "find-mother-detail",
        fetchFullReport: "fetch-full-report",
        fetchIntialDetails :"intial-details"

    };

    static readonly workingHour = {
        base: "working-hour",
        fetch: "fetch-all",
        insert: "add",
        update: "update",
        modifyStatus: "modify-status",
        fetchActive: "fetch-active"
    };

    static readonly encounterType = {
        base: "encounter-type",
        add: "add",
        fetch: "fetch",
        update: "update",
        delete: "delete",
        modifyStatus: "modify-status",
    };
    static readonly userImport = {
        base: "user-import",
        fetchUploadHistory: "fetch-upload-history",
        addUserSheet: "add-user-sheet",
        fetchUplodedUsers: "fetch-uploaded-users"
    }


    static readonly sessionType = {
        base: "sessiontype",
        fetch: "fetch",
        delete: "delete",
        modify: "modify",
        modifyStatus: "modify-status",
        fetchSessionTypes: "fetch-session-types",
        insert: "add",
        update: "update"

    };

    static readonly session = {
        base: "session",
        fetch: "fetch",
        add: "add",
        delete: "delete",
        modifyStatus: "modify-status",
        update: "update",
        addSessionLeave: "add-session-leave",
        updateSessionLeave: "update-session-leave",

    };

    static readonly patientChatBox = {
        base: "patient-chat-box",
        fetch: "fetch-all",
        add: "add",

    };

    static readonly chargeModule = {
        base: "charge-module",
        fetchChargeTemplates: "fetch-charge-templates",
        modifyChargeTemplate: "modify-charge-template",
        modifyCharge: "modify-charge",
        fetchGivenCharges: "fetch-given-charge",
        fetchLocationChargePrices: "fetch-location-charge-prices",
        updateLocationChargePrices: "update-location-charge-prices",
        deleteCharge: "delete-charge",
        setUseTemplate: "set-in-use-template",
        fetchDoctorSpecializationGivenCharges: "fetch-doctor-specialization-given-charge",
        modifyDoctorSpecializationConsultationTypeCharge: "modify-doctor-specialization-charge",
        deleteDoctorSpecializationCharge: "delete-doctor-specialization-charge",
        fetchorCreateReferenceId: "fetch-or-create-referenceid",
        fetchorCreateReferenceIdForIpDoctor: "fetch-or-create-referenceid-ipdoctor"
    };

    static readonly otRoomAvailability = {
        base: "ot-Room-availability",
        fetchAll: "fetch-all",
        modifyOperationAvailability: "modify-ot-Room-availability",
        update: "update",
        delete: "delete",
        modifyStatus: "modify-status",
        fetchActive: "fetch-active",
        fetch: "fetch",
        fetchSpecific: "fetch-specific",
        fetchSlotsForMulitiple: "fetch-slots-for-multipledays"

    };

    static readonly otRegister = {
        base: "ot-Register",
        add: "modify-ot-Register", //
        fetch: "fetch-all",
        fetchRegister: "fetch-data",
        update: "modify-ot-Register",
        delete: "delete",
        cancel: "cancel",
        reschedule: "reschedule",
        fetchEventFilter: "fetch",
        fetchOTAppointments: "fetch-ot-appointment",
        fetchpatientDetail: "ot-distinct-patients",
        fetchAllsurgerys: "fetch-all-surgerys"
        // modifyOTRegister: "modify-ot-register"
        // fetchOTRoom: "fetch-ot-register"
    };
    static readonly templateConfig = {
        base: "template-config",
        fetch: "fetch-all",
        insert: "add",
        update: "update",
        modifyStatus: "modify-status",
        fetchDynamicTemplates: "fetch-dynamic-reports",
        fetchReportConfigs: "fetch-report-configs"
    };

    static readonly caseType = {
        base: "case-type",
        fetch: "fetch-all",
        insert: "insert",
        update: "update",
        modifyStatus: "modify-status"

    };
    static readonly doctorUnit = {
        base: "doctor-unit",
        insert: "insert",
        update: "update",
        fetch: "fetch-all",
        modifyStatus: "modify-status",
        fetchUnitWithCharges: "fetch-Unit-with-charges",
        fetchTemplateDoctorUnitCharges: "fetch-template-doctorunit-charges"
    }

    static readonly paymentMapHelper = {
        base: "payment-maphelper",
        fetch: "fetch-added-bill-id"
    }

    static readonly patientImport = {
        base: "patient-import",
        fetchUploadHistory: "fetch-upload-history",
        addPatientSheet: "add-patient-sheet",
        fetchUplodedPatients: "fetch-uploaded-patients"
    }
    static readonly healthCard = {
        base: "health-card",
        add: "add",
        update: "update",
        fetch: "fetch",
        modifyStatus: "modify-status",
        fetchIssuedCards: "fetch-issued-cards",
        issuedNewCard: "add-new-card",
        fetchCardDetails: "card-details",
        fetchReport: "check-patient-health-card-exist",
        fetchCardId: "fetch-health-card"
    }

    static readonly ambulance = {
        base: "ambulance",
        fetch: "fetch",
        insert: "add",
        update: "update",
        /*delete: "delete",*/
        modifyStatus: "modify-status"
    };

    static readonly driverDetail = {
        base: "driver-Detail",
        fetch: "fetch",
        insert: "add",
        update: "update",
        /*delete: "delete",*/
        modifyStatus: "modify-status"
    };

    static readonly ambulanceReceipt = {
        base: "ambulance-Receipt",
        fetch: "fetch",
        insert: "add",
        update: "update",

    };

    static readonly reason = {
        base: "reasons",
        fetch: "fetch-all",
        insert: "add",
        update: "update",
        delete: "delete",
        modifyStatus: "modify-status",
    };
    static readonly authority = {
        base: "authorityMaster",
        fetch: "fetch-all",
        insert: "add",
        update: "update",
        delete: "delete",
        modifyStatus: "modify-status",
    };

    static readonly initialVendor = {
        base: "initial-vendor",
        insertRequest: "insert-request",
        fetchRequestedProducts: "fetch-requested-product",
        fetchVendorPurchaseHistory: "fetch-vendor-product-purchase-history",
        addVendorProductForApproval: "add-vendor-product-for-approval",
        fetchVendorProductForApproval: "fetch-vendor-product-for-approval",
        addTenderToVendor: "add-tender-to-supplier",
        addDirectProductRequest: "add-direct-product-request",
        raisePO: "raise-po",
        fetchPOHeader: "fetch-po-header",
        fetchPODetail: "fetch-po-detail",
        fetchPONumber: "fetch-po-number",
        addGRN: "add-grn",
        approveOrReject: "approve-or-reject-product",
        addForQuotation: "add-for-quotation",
        fetchProductForQuotationHeader: "fetch-product-for-quotation",
        modifyInventoryRequest: "modify-inventory-request",
        fetchInventoryRequest: "fetch-inventory-request",
        fetchPendingInventoryRequests: "fetch-pending-inventory-request",
        updateInventoryProductRequest: "update-product-for-pending-inventory-request",
        approveInventoryRequest: "approve-inventory-request",
        changeVendorAtFinalPO: "change-vendor-at-po",
        fetchAddedPurchaseByGrn: "fetch-added-purchase-by-grn",
        approveAllProduct: "approve-all-product",
        checkBillAgainstPO: "check-bill-against-po",
        fetchAllPONumber: "fetch-all-po-number",
        cancelPo: "Cancel-Po",
        fetchPoAllHeader: "fetch-po-all-header",
        fetchPoAllDetail: "fetch-po-all-detail",
        fetchPoStatus: "fetch-po-status",
        fetchUploadedGrnDocument: "fetch-UploadedDocument",
        downloadFileGet: "download-file-ftp-get",
        insertReason: "insert-reason",
        fetchReasons: "fetch-reasons-returntovendor",
        checkBatchNumber: "check-batch-number",
        changeStatus: "change-status-isrejected",
        raiseDirectPo:"raise-direct-po"
    };

    static readonly encounterTemplate = {
        base: "encounter-template",
        modifyTemplate: "modify-template",
        fetchTemplate: "fetch-template",
        modifystatus: "modify-status",
        fetch: "fetch-template-detials",
        delete: "delete"
    };

    static readonly vaccine = {
        base: "vaccine",
        modifyGroup: "modify-vaccine-group",
        getAllGroup: "fetch-all-vaccine-group",
        deleteGroup: "delete-group",
        modifyVaccineMaster: "modify-vaccine-master",
        fetchVaccineMaster: "fetch-vaccine-master",
        fetchVaccineType: "fetch-vaccine-type",
        setOrder: "set-vaccine-master-order",
        modifyVaccineMasterStatus: "modify-vaccine-master-status",
        getVaccineSchedule: "get-immunization-schedule",
        getAgeGroup: "fetch-vaccine-age-group",
        modifyPharmaLink: "modify-pharmacy-link",
        fetchPharmaLink: "fetch-pharmacy-link",
        deletePharmaLink: "delete-pharmacy-link",
        fetchVaccinesForOrder: "fetch-vaccines-for-order",
        orderVaccine: "order-vaccine",
        checkVaccineProductInSalesBill: "check-vaccine-product",
        giveVaccine: "give-vaccine",
        fetchPatientImmunizationHistory: "fetch-patient-immunization-history"
    };

    static readonly gatePass = {
        base: "gatePass",
        gatePassinsert: "add",
        gatePassfetch: "fetch",
        gatePassupdate: "update",
        checkout: "check-out"
    };

    static readonly message = {
        base: "message",
        add: "add",
        fetch: "fetch",
        update: "update"
    }

    static readonly masterbill = {
        base: "masterbill",
        insert: "add",
        fetch: "fetch",
        fetchPackages: "fetch-packages",
        fetchforExcel: "fetch-for-excel",
        fetchPatientDue: "fetch-patient-due",
        modifyBillingComments: "modify-billing-comments",
        fetchBillingComments: "fetch-billing-comments",
        email: "pdf-email"
    };

    static readonly dietEncounter = {
        base: "diet-plan-encounter",
        find: "find",
        findDashboard: "find-dashboard",
        //findPrescription: "find-prescription",
        modify: "modify",
        findVisitNo: "visit-no",
        findFullTrascript: "find-prescription",
        findgpla: "find-gpla",
        completedEncounter: "encounter-completed",
        findPatientDietPlan: "fetch-diet-plan",
        findEncounterType: "fetch-encounter-type",
        findEncounterLookup:"encounter-lookup"
    };



    static readonly dietGuidlines = {
        base: "diet-guidlines",
        insert: "add",
        update: "update",
        fetch: "fetch",
        modifyStatus: "modify-status",
        downloadFile: "download-file-ftp",
        downloadFileGet: "download-file-ftp-get",
        delete: "delete"
    }

    static readonly pediatricChart = {
        base: "pediatric-chart",
        modifyAuthority: "modify-authority",
        fetchAuthority: "fetch-authority",
        changeAuthorityStatus: "change-authority-status",
        fetchChartType: "fetch-chart-type",
        pediatricDisplay: "pediatric-display",
        fetchAgeWiseData: "fetch-age-wise-data",
        fetchAgeWiseZScoreData: "fetch-age-wise-z-score-data"
    };

    static readonly relations = {
        base: "relations",
        fetch: "fetch",
        add: "add",
        edit: "edit",
        modifyStatus: "modify-status",


    }
    static readonly denverCharts = {
        base: "denver-charts",
        add: "add",
        fetch: "fetch",
        fetchbyid: "fetchbyid"
    }
    static readonly doctorAppointmentNotice = {
        base: "doctor-appointment-notice",
        fetch: "fetch",
        add: "add",
        edit: "edit",
        delete: "delete",
        modifyStatus: "modify-status"
    }

    static readonly scanAppointmentNotice = {
        base: "scan-appointment-notice",
        fetch: "fetch",
        add: "add",
        edit: "edit",
        delete: "delete",
        modifyStatus: "modify-status"
    }
    static readonly emergencyEncounter = {
        base: "emergency-encounter",
        findDashboard: "find-dashboard",
        modify: "modify",
        find: "find",
        findAll: "find-all",
        closeEncounter: "close"
    }

    static readonly diet = {
        base: "diet",
        addShift: "add-shift",
        updateShift: "update-shift",
        fetch: "fetch",
        modifyStatus: "modify-status",

    };

    static readonly dietItems = {
        base: "diet-items",
        add: "add",
        fetchMeasure: "fetch-measure",
        fetchItems: "fetch-items",
        update: "update",
        modifyStatus: "modify-status"
    };

    static readonly dietConditions = {
        base: "diet-condition",
        add: "add",
        update: "update",
        modifyStatus: "modify-status",
        fetchDietPackage: "fetch-diet-packages",
        viewPackage: "view-package",
        delete: "delete-package",
        modifyPackage: "modify-status-package",
        dietItems: "diet-items",
        findPackage: "find-package"
    };
    static readonly birthCertificate = {
        base: "birth-certificate",
        add: "add",
        update: "update",
        fetch: "fetch",
        fetchBirthReport: "fetch-birth-report"
    }
    static readonly deathCertificate = {
        base: "death-certificate",
        add: "add",
        update: "update",
        fetch: "fetch",
        fetchBirthReport: "fetch-birth-report"
    }
    static readonly inpatientsView = {
        base: "inpatients-view",
        fetch: "fetch",
        fetchBeds: "fetch-beds"
    };

    static readonly Tpa = {
        base: "tpa",
        add: "add",
        update: "update",
        fetch: "fetch",
        changeStatus: "change-status"

    };

    static readonly inventoryRequest = {
        base: "inventory-request",
        modifyInventoryRequest: "modify-inventory-request",
        fetch: "fetch"
    };

    static readonly vendorQuotation = {
        base: "vendor-quotation",
        addIncomingQuotation: "add-incoming-quotation",
        fetchIncomingQuotation: "fetch-incoming-quotation",
        deleteQuotation: "delete-quotation",
        acceptQuotation: "accept-quotation"
    };

    static readonly geneticSpecialityEncounter = {
        base: "genetic-speciality-encounter",
        find: "find-fulltranscript",
        findDashboard: "find-dashboard",
        modify: "modify",
        completedEncounter: "encouter-completed"
    }

    static readonly whatsAppTickets = {
        base: "whatsapp-tickets",
        fetch: "get-alltickets",
        changeStatus: "ChangeStatus"
    }

    static readonly register = {
        base: "register",
        modify: "modify",
        fetchReport: "fetch-report",
        fetchRegisters: "fetch-registers",
        fetch: "fetch",
    }
    static readonly physiotherapyEncounter =
        {
            base: "physiotherapy-encounter",
            add: "add",
            fetch: "fetch",
            update: "update",
            findFullTranscript: "find-full-transcript",
            findFutureAppointment: "find-future-appointments",
            completedEncounter: "encounter-completed"
        }

    static readonly insuranceTemplate = {
        base: "insurance-templates",
        add: "add",
        fetch: "fetch",
        update: "update",
        delete: "delete",
        fetchInsuranceTemplate: "fetch-insurance-template",
        changestatus: "change-status"
    };
    static readonly encountersLog = {
        base: "encounterslog",
        fetch: "fetch",
        insert: "insert"
    }
    static readonly paytm = {
        base: "paytm",
        payment: "payment",
        status: "status"
    };


    static readonly ccavenue = {
        base: "ccavenue",
        initiatePayment: "initiate-payment"
    };


    static readonly cims = {
        base: "cims",
        drugInformation: "drug-info",
        drugToDrugInteraction: "drug-to-drug-interaction",
        drugToHealthInteraction: "drug-to-health-interaction",
        alterFlterByDrug: "alter-filter-by-drug",
        allergyApi:"allergy-api"
    };

    static readonly nurseAssessmentForms = {
        base: "nurse-assessment-forms",
        modify: "modify",
        find: "find",
        findAll: "find-all",
        fetch: "fetch",
    };

    static readonly webAlert = {
        base: "webalert",
        fetch: "fetch-webalerts",
        add: "add-webalerts",
        acceptWebAlert: "accept-webalerts",
        clearWebAlert: "claer-webAlert"
    };
    static readonly userAlert = {
        base: "useralert",
        acceptUserAlert: "accept-useralert",
        rejectUserAlert: "reject-useralert"
    };

    static readonly dischargeAlert = {
        base: "discharge-Alerts",
        fetch: "fetch",
        add: "add",
        modifyPriority: "modify-priority",
        modifyPriorities: "modify-priorities",
        modifyStatus: "modify-status",
        addReadyToDischarge: "add-ready-to-discharge",
        cancelReadyToDischarge: "cancel-ready-to-discharge"

    }

    static readonly readyToDischarge = {
        base: "ready-to-discharge",
        fetch: "fetch"
    }
    static readonly lookup = {
        base: "lookup",
        find: "fetch-lookup",
        findlookupvalue: "fetch-lookupvalue",
        insert: "insert",
        update: "update",
        delete: "delete",
        changeStatus: "change-status",
        findencounterlookup: "fetch-encounter-lookup",
        findencouonterlookupvalue: "fetch-encounter-lookupvalue",
        add: "insert-lookup",
        modifiy: "update-lookup",
        remove: "delete-lookup",
        changeStatuslookupvalue: "change-status-encounterlookup"
    }
    static readonly patientDiet = {
        base: "patient-diet",
        fetchAdmittedPatients: "fetch-admitted-patient",
        addIndent: "add-indent",
        approveDietIndent: "approve-diet-indent",
        fetchKitchenManagerSheet: "fetch-kitch-manager-sheet",
        fetchDietIndentRport: "fetch-diet-indent-report",
        supervisiorRequest: "request-to-supervisior",
        fetchDietSupervisor: "fetch-diet-supervisor",
        deliverBySupervisor: "deliver-by-supervisor"
    }
    static readonly DietPlanType = {
        base: "DietPlanType",
        find: "fetch-dietplantype",
        insert: "insert",
        update: "update",
        delete: "delete",
        changeStatus: "change-status"

    }

    static readonly insuranceManagement = {
        base: "insurance-management",
        addReceipt: "insert",
        upload: "upload",
        updatePostDiscount: "update-post-discount"
    };

    static readonly deatailsOfBirthCertificateTelangana = {
        base: "detailsOfBirthCertificateTelangana",
        add: "add",
        update: "update",
        fetch: "fetch"
    }

    static readonly patientLog = {
        base: "patientlog",
        fetch: "fetch-patient-log",
        insertLabLogs: "insert-lab-logs"
    };

    static readonly patientFeedback = {
        base: "patient-feedback",
        fetch: "fetch",
        add: "add",

    }
    static readonly patientWhatsappFeedback =
        {
            base: "patientWhatsappFeedback",
            add: "add",
            fetch: "fetch"
        }
    static getURI(base: string, endPoint: string): string {
        const apiEndPoint = `${base}/${endPoint}`;
        let localSettings = localStorage.getItem("settings");
        let localSettingsFromSession = sessionStorage.getItem("settingsUrls");
        if (localSettings) {
            let setting = JSON.parse(localSettings);
            return setting["baseURI"] + apiEndPoint;
        }
        else if (localSettingsFromSession) {
            let setting = JSON.parse(localSettingsFromSession);
            return setting["baseURI"] + apiEndPoint;
        } else {
            if (AppConfig.settings) {
                return AppConfig.settings.baseURI + apiEndPoint;
            } else {
                let origin = location.origin;
                let baseURI = "";
                if (origin.includes("localhost")) {
                    baseURI = "http://localhost:62545/api/";
                } else {
                    baseURI = `${origin}/fernandez-api/api/`;
                }
                return baseURI + apiEndPoint;
            }
        }
    }

    static readonly specializationUnit = {
        base: "specializationUnits",
        insert: "add",
        fetch: "fetch",
        update: "update",
        modifyStatus: "modify-status"
    }
    static readonly labDocumentDetails = {
        base: "lab-document-details",
        fetch: "fetch",
        upload: "upload",
        delete: "delete",
        downloadFile: "download-file-ftp",
        downloadAllFile: "download-all-file-ftp",
        downloadFileGet: "download-file-ftp-get",

    };
    static readonly consentForms = {
        base: "consent-forms",
        insert: "insert-consent",
        fetch: "fetch",
        upload: "update-consent",
        delete: "delete",
    };

    static readonly whatsAppService = {
        base: AppConfig.settings?.whatsappServiceBase,
        sendLocation: "sendLocation",
        sessionBooking: "sessionBooking",
        appointmentBooking: "send",
        appointmentReschedule: "reshedule",
        appointmentRescheduleRemainder : "rescheduleRemainder",
        appointmentCancel: "cancel",
        scanBooking: "scan_booking_confirmation",
        scanReschedule: "scan_reschedule",
        scanCancel: "scan_cancel",
        ipPatient: "ipPatient",
        sendNutritionForm: "sendNutritionForm",
        doctorReference: "doctor_reference",
        sendTxtMsg: "sendTxtMsg",
        getParameters: "whatsAppParameters",
        getTemplates: "whatsAppTemplates",
        sendMessage: "SendMessage"
    };
    static readonly saveConsentForms = {
        base: "save-consent-forms",
        insert: "insert-consent",
        fetch: "fetch",
        upload: "update-consent",
        delete: "delete",
    };
    static readonly dischargeRegister = {
        base: "dischare-register",
        insert: "insert-register",
        fetch: "fetch",
        upload: "update-register",
        delete: "delete",
    };

    static readonly callCenterInfo = {
        base: "call-centre",
        fetchCallDispositions: "fetch-call-dispositions",
        fetchRemarks: "fetch-remarks",
        fetchNewPatientRemarks: "fetch-call-center-remarks",
        addCallCenterInfo: "add-call-center-remarks",
        fetchPatients: "fetch-patient-with-name-umr-mobile",
        fetchCallCentreAppointments: "fetch-call-centre-appoinments",
        fetchCallCentreScanAppointments: "fetch-call-centre-scan-appoinments",
        fetchProviders:"fetch-providers"
    }
    static readonly reportConfig = {
        base: "report-config",
        insert: "insert-report",
        fetch:"fetch",
        update: "update",
        delete: "delete",
        fetchName: "fetch-Name",
        modifyStatus: "modify-status"
    }    
    
    static readonly GC = {
        base: "gc",
        fetchAllCalls: "get-all-calls",
        fetchPrevCalls: "fetch-prev-calls",
        fetchCallHistory: "fetch-call-history",
        insertCall: "insert-call",
        fetchDataForComplaint: "fetch-data-for-complaint",
        fetchComplaintBody: "fetch-complaint-body",
        insertComments: "insert-comments",
        fetchComplaintDocuments: "fetch-complaint-documents",
        viewAttachedDocuments: "view-attached-Documents",
        uploadGcDocuments: "upload-gc-documents",
        fetchClientData: "fetch-client-data",
       fetchSupportCount: "fetch-support-count"
    };

    static readonly patientserviceslog = {
        base: "patient-services-log",
        fetch: "fetch",
        insert: "insert"
    };
    static readonly ophthalmologyEncounter = {
        base: "ophthalmology-encounter",
        findDashboard: "find-dashboard",
        modify: "modify",
    }

    static readonly countries = {
        base: "countries",
        addState: "add-state",
        fetchStates: "fetch-states",
        updateState: "update-state",
        fetchCities: "fetch-cities",
        addCity: "add-city",
        updateCity: "update-city",
        modifyCityStatus: "modify-city-status",
        modifyStateStatus: "modify-state-status",
        fetchCountries: "fetch-countries",
        addCountry: "add-country",
        updateCountry: "update-country",
        modifyCountryStatus: "modify-country-status"
    };

    static readonly howDidYouKnow = {
        base: "howdidyouknow",
        insert: "add",
        fetch: "fetch",
        update: "update",
        modifyStatus: "modify-status"
    }
    static readonly education = {
        base: "education",
        insert: "add",
        fetch: "fetch",
        update: "update",
        modifyStatus:"modify-status"
    }

    static readonly occupation = {
        base: "occupation",
        fetchOccupations: "fetch-occupations",
        addOccupation: "add-occupation",
        updateOccupation: "update-occupation",
        modifyStatus: "modify-status"
    };

    static readonly surgeonCharge = {
        base: "surgeon-charge",
        fetchAll: "fetch-all",
        modify: "modify-surgeon-charge",
        modifyStatus: "modify-surgeon-charge-status",
        fetchTemplateSurgeonCharges: "fetch-template-surgeon-charges",

    };
}